import { Injectable } from '@angular/core';
import country_list from '../../../../app/shared/data/countries_states.json';
import usa_states_list from '../../../../app/shared/data/usa_states_list.json';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class BatutaService {

  constructor(
    private http: HttpClient ) { }

  getCountries() {
    return country_list.data.map(obj => {return {name: obj.name, code: obj.code}});
  }

  getStates(country) {
    let countryData = country_list.data.find(obj => obj.name.toLowerCase() == country.toLowerCase());
    return countryData.states.map(obj => {return {text: obj.name}})
  }

  async getToken(){
    const headers = new HttpHeaders({
      Accept: `application/json`,
      'api-token': environment.tokenUniversal.token,
      'user-email': "alan@trueomni.com"
    });
    const token = await this.http.get<any>("https://www.universal-tutorial.com/api/getaccesstoken", {
      headers,
    }).pipe(take(1)).toPromise();
    console.log(token)
    return token.auth_token
  }
}
