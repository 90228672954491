import { AgmMap, LatLngBounds } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Listing } from '@app/core/data/Listing';
import { environment } from '@env/environment';
import { Events, IonSlides } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationEntity } from '../../list-details/list-details.page';
import { timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import Long from 'long';
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-scavenger-hunts-map',
  templateUrl: './scavenger-hunts-map.component.html',
  styleUrls: ['./scavenger-hunts-map.component.scss'],
})
export class ScavengerHuntsMapComponent implements OnInit, AfterViewInit  {
  @ViewChild(AgmMap) agmMap;
  @ViewChild('slideWithNav3') slideWithNav3: IonSlides;
  @Input() listings: Listing[];
  @Input() fitBound: boolean = false;
  @Input() zoom: number = 13;
  @Input() minZoom: number = 13;
  @Input() maxZoom: number = 16;
  @Input() customIcon: string = '';

  latitude: number;
  longitude: number;
  userLocation!: LocationEntity;
  selectedItem!: Listing;
  slideImages: string [];
  loadCarousel = true;
  previousIndex: number;
  windowH: number;
  mapH: number;
  @Input() isLoading: Boolean;

  public mapStyles = [
    {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
      ]
    }
  ];

  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 1.15,
    spaceBetween: 15,
    center: true,
    centeredSlides: true,
    centeredSlidesBounds: true
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geolocation: Geolocation,
    public events: Events,
    private storage: Storage,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.latitude = environment.cms.main_coordinates.latitude;
    this.longitude = environment.cms.main_coordinates.longitude;
  }

  init(){
    this.ngZone.run(() => {
      // Actualiza la interfaz de usuario y activa la detección de cambios si es necesario
      this.events.subscribe('testevent',async  (data) => {
        // if (data.listings) {
        //   this.listings = data.listings.slice(0,100);
        // }
        // var filteredArray = this.listings.filter(function (itm) {
        //   return itm.Latitude;
        // });
        // this.listings = filteredArray;
        // this.trackLocation();

        // this.slideImages = this.listings.map(x=> {
        //   if (!x.Image_List) {
        //     return "assets/placeholder/placeholder.png";
        //   }
        //     var pene =  x.Image_List.split("|")[0];
        //     return pene;
        // });
        var filteredArray = this.listings.filter(function (itm) {
          return itm.Latitude;
        });
        this.listings = filteredArray.filter(obj => obj.Task.Definition.Type != 'Question');
        this.setDistance();
        this.listings.sort(function (a, b) {
          return a.Distance - b.Distance;
        });
        this.listings = this.listings.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.ListingID === item.ListingID)
        );
        this.slideImages = this.listings.map(x=> {
          if (!x.Image_List) {
            return "assets/placeholder/placeholder.png";
          }
            var slideImage =  x.Image_List.split("|")[0];
            return slideImage;
        });
         await this.slideWithNav3.update();
        //this.slideWithNav3.slideTo(0);
      });
    });
  }
  ngAfterViewInit() {
    this.init();
  }
  async ngOnInit() {
    var filteredArray = this.listings.filter(function (itm) {
      return itm.Latitude;
    });
    this.listings = filteredArray;
    this.getCurrentLocation();
    this.listings = this.listings.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.ListingID === item.ListingID)
    );
    this.listings.sort(function (a, b) {
      return a.Distance - b.Distance;
    });
    this.slideImages = this.listings.map(x=> {
      if (!x.Image_List) {
        return "assets/placeholder/placeholder.png";
      }
        var slideImage =  x.Image_List.split("|")[0];
        return slideImage;
    });
    if (this.slideWithNav3) {
      await this.slideWithNav3.update();
      await this.slideWithNav3.slideTo(0);
    }
    this.latitude = this.listings[0].Latitude ? this.listings[0].Latitude : 0;
    this.longitude = this.listings[0].Longitude ? this.listings[0].Longitude : 0;
    this.listings[0].zIndex = 1000;
    // if (!this.fitBound) {
    //   this.windowH = window.innerHeight-330;
    //   this.mapH = window.innerHeight-50;
    //   this.listings[0].IconWidth = 45;
    //   this.listings[0].IconHeight = 60;
    //   }
    // else {
    //   this.windowH = window.innerHeight-580;
    //   this.mapH = window.innerHeight-440;
    //     if (this.customIcon.length > 0)
    //     this.listings[0].Icon = this.customIcon;
    // }
    this.windowH = window.innerHeight-438;
    this.mapH = window.innerHeight-240;
    if (this.customIcon.length > 0)
      this.listings[0].Icon = this.customIcon;
    this.listings[0].IconWidth = 45;
    this.listings[0].IconHeight = 60;
    this.previousIndex = 0;

  }
  metersToMi(meters: number): number {
    return meters * 0.000621371;
  }

  async getCurrentLocation() {
    await this.geolocation
      .getCurrentPosition({ enableHighAccuracy: true, timeout: 2000 })
      .then(async (resp: any) => {
        this.isLoading = false;
        if (resp.code == 3 || resp.code == 2) {
          const lastLocation = await this.storage.get("lastLocation");
          this.userLocation = lastLocation;
          this.trackLocation();
        } else {
          this.userLocation = {
            latitude: resp.coords.latitude,
            longitude: resp.coords.longitude,
          };
          this.storage.set("lastLocation", this.userLocation);
          this.trackLocation();
        }

      }).catch(async err => {
        if (err.code == 3 || err.code == 2) {
          const lastLocation = await this.storage.get("lastLocation");
          if (!lastLocation) this.getCurrentLocation();
          else{
            this.userLocation = lastLocation;
            this.trackLocation();
          }
        }

      });
  }


  trackLocation() {
    // this.geolocation.getCurrentPosition().then((resp) => {
    //   this.isLocationAvailable = true;
      if (this.listings) {
        this.listings = this.listings.map(list => {
          list.Distance = this.calcularDistancia(this.userLocation.latitude, this.userLocation.longitude, list.Latitude, list.Longitude);
          return list
        })
        this.listings = this.ordenarPorDistancia(this.listings, this.userLocation)
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }

  }

  calcularDistancia(latitud1: number, longitud1: number, latitud2: number, longitud2: number): number {
    const radioTierraKm = 6371; // Radio de la Tierra en kilómetros
    const latitud1Rad = this.gradosARadianes(latitud1);
    const latitud2Rad = this.gradosARadianes(latitud2);
    const diferenciaLatitud = this.gradosARadianes(latitud2 - latitud1);
    const diferenciaLongitud = this.gradosARadianes(longitud2 - longitud1);

    const a =
      Math.sin(diferenciaLatitud / 2) * Math.sin(diferenciaLatitud / 2) +
      Math.cos(latitud1Rad) * Math.cos(latitud2Rad) *
      Math.sin(diferenciaLongitud / 2) * Math.sin(diferenciaLongitud / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distancia = radioTierraKm * c;

    return Number(distancia.toFixed(2));
  }

  gradosARadianes(grados: number): number {
    return (grados * Math.PI) / 180;
  }

  ordenarPorDistancia(listings: Listing[], userLocation: LocationEntity): Listing[] {
    return listings.sort((list, list2) => {
      // const distancia1 = calcularDistancia(coord1, referencia);
      // const distancia2 = calcularDistancia(coord2, referencia);
      return list.Distance - list2.Distance;
    });
  }
  setDistance() {
    this.mapsAPILoader.load().then(() => {
      if (!this.userLocation) this.userLocation = environment.cms.main_coordinates;
      var location = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
      this.listings.forEach(item => {
        if (item.Latitude && item.Longitude) {
          const listingLocation = new google.maps.LatLng(item.Latitude, item.Longitude);
          if (google.maps.geometry) {
            const distanceInM = google.maps.geometry.spherical.computeDistanceBetween(location, listingLocation);
            item.Distance = parseFloat(this.metersToMi((Math.round(distanceInM * 100) / 100)).toFixed(2));
          }
          else {
            const distanceInM = this.distance(location.lat, location.lng, listingLocation.lat, listingLocation.lng);
            item.Distance = parseFloat(this.metersToMi((Math.round(distanceInM * 100) / 100)).toFixed(2));
          }
        }
      });
      this.listings.sort(function (a, b) {
        return a.Distance - b.Distance;
      });

    }
  );

  }
  listingClick(listing: any){
      this.router.navigate(["/tabs/listings", listing.Task.Definition.ListingId], {
        queryParams: {
          subcategoryTitle: listing.SubCategoryName,
          hasCheckedIn: listing.Task.IsCompleted,
          type: listing.Task.Definition.Type,
          trailId: listing.Task.Definition.TrailId,
          taskId: listing.Id,
          taskType: listing.Task.Definition.Type,
          latitude: listing.Task.Definition.Geofence.Latitude,
          longitude: listing.Task.Definition.Geofence.Longitude,
          radius: listing.Task.Definition.Geofence.Radius,
          points: listing.Task.Definition.Value,
          Description: listing.Task.Definition.Description,
          Image_List: listing.Task.Definition.Image,
          Image: listing.Task.Definition.Image,
          Latitude: listing.Task.Definition.Geofence.Latitude,
          Longitude: listing.Task.Definition.Geofence.Longitude,
          Title: listing.Task.Definition.Title,
        },
      });
  }

  deg2rad(deg) {
    return (deg * Math.PI / 180.0);
  }
  rad2deg(rad) {
    return (rad * 180.0 / Math.PI);
  }
  distance(lat1, lon1, lat2, lon2) {
    var theta = lon1 - lon2;
    var  dist = Math.sin(this.deg2rad(lat1))
                    * Math.sin(this.deg2rad(lat2))
                    + Math.cos(this.deg2rad(lat1))
                    * Math.cos(this.deg2rad(lat2))
                    * Math.cos(this.deg2rad(theta));
    dist = Math.acos(dist);
    dist = this.rad2deg(dist);
    dist = dist * 60 * 1.1515;
    return (dist);
  }
  selectMarker(event, item: Listing, i) {
    if (event.cancelable)
      event.preventDefault();
    //event.preventDefault();
    this.slideWithNav3.slideTo(i);
    //this.zoom = 13; // can set a default zoom if needed
  }

  onOpenListingClick(item: Listing) {
    this.router.navigate(['/listings', item.ListingID]);
  }
  onZoomChange(newZoomValue) {
    this.zoom = newZoomValue;
}
  //Method called when slide is changed by drag or navigation
  SlideDidChange(event, slideView) {
      if (this.previousIndex == 0) {
        this.listings[this.previousIndex].IconWidth = 30;
        this.listings[this.previousIndex].IconHeight = 40;
      }
      else {
        this.listings[this.previousIndex].IconWidth = 22;
        this.listings[this.previousIndex].IconHeight = 30;
      }
    this.listings[this.previousIndex].zIndex = 1;

    this.slideWithNav3.getActiveIndex().then(index => {
      this.latitude = this.listings[index].Latitude;
      this.longitude = this.listings[index].Longitude;

      //this.listings[index].Icon = "./assets/map/big-pin.png";
      if (index == 0) {
        this.listings[index].IconWidth = 45;
        this.listings[index].IconHeight = 60;
      }
      else {
        this.listings[index].IconWidth = 45;
        this.listings[index].IconHeight = 60;
      }
      this.listings[index].zIndex = 1000;
      this.previousIndex = index

   });
   if (event.cancelable)
    event.preventDefault();

  }
}
