import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrailsService } from '@app/shared/services/trails.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-awards',
  templateUrl: './modal-awards.component.html',
  styleUrls: ['./modal-awards.component.scss'],
})
export class ModalAwardsComponent implements OnInit {

  leaderboard: any;
  public language: string;
  awards: any  = [];
  pins: any = [];
  constructor(
    private trailsService: TrailsService,
    private router: Router,
    private modalCtrol: ModalController,
    private translateService: TranslateService,) {
      this.language = this.translateService.currentLang;
      this.pins = [
        {
          id: 1,
          quantityForGet: 25,
          image: `assets/pins/pinOne${this.language || "en"}.png`,
          obtained: false,
          percentage: 0,
        },
        {
          id: 2,
          quantityForGet: 50,
          image: `assets/pins/pinTwo${this.language || "en"}.png`,
          obtained: false,
          percentage: 0,
        },
        {
          id: 3,
          quantityForGet: 75,
          image: `assets/pins/pinTree${this.language || "en"}.png`,
          obtained: false,
          percentage: 0,
        },
      ];
     }

  async ngOnInit() {
    await this.trailsService.getLeadership().then(value => {
      this.leaderboard = value;
      this.awards = this.leaderboard.AwardListResult;
      switch (this.leaderboard.Pins) {
        case 3:
          this.pins[0].obtained = true;
          this.pins[1].obtained = true;
          this.pins[2].obtained = true;
          break;
        case 2:
          this.pins[0].obtained = true;
          this.pins[1].obtained = true;
          break;
        case 1:
          this.pins[0].obtained = true;
          break;
        default:
          break;
      }
    })
  }

  goToTrails(resp: boolean){
    if (resp){
      this.router.navigate(["tabs/trails"]);
      this.modalCtrol.dismiss(false);
    }else{
      this.modalCtrol.dismiss(false);
    }
  }

}
