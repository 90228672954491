import { AgmMap, MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Listing } from '@app/core/data/Listing';
import { AlertService } from '@app/shared/services/alert.service';
import { TrailsService } from '@app/shared/services/trails.service';
import { environment } from '@env/environment';

import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { Events, IonSlides, ModalController } from '@ionic/angular';
import { LocationEntity } from '../list-details.page';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TrailCompletedComponent } from '@app/codebase/trails/trail-completed/trail-completed.component';
import { BeerOMeterComponent } from '@app/codebase/trails/beer-o-meter/beer-o-meter.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Storage } from "@ionic/storage";
import moment from 'moment';
import { DistanceUtilsService } from '@app/shared/services/distance-utils.service';
declare function require(name:string);

@Component({
  selector: 'app-trail-map-checkin',
  templateUrl: require('template/' + environment.template + '/html/trail-map-checkin.component.html'),
  styles: [require('template/' + environment.template + '/styles/trail-map-checkin.component.scss')]
})
export class TrailMapCheckinComponent implements OnInit {

  public environment = environment;  @Input() listings?: Listing[];
  @Input() fitBound?: boolean = false;
  @Input() zoom?: number = 130;
  @Input() minZoom?: number = 5;
  @Input() maxZoom?: number = 16;
  @Input() customIcon?: string = '';
  @Input() radius?: number;
  @Input() trailId?: any;
  @Input() currentLocation?: any;
  // @Input() userLongitude?: number;

  @Input() isLoading: Boolean;
  previousIndex: number;
  windowH: number;
  mapH: number;
  private geolocationSubscription: Subscription;
  @ViewChild(AgmMap) agmMap;
  @ViewChild('cameraInputFile') cameraInputFile!: ElementRef;
  @ViewChild('slideWithNav2') slideWithNav2: IonSlides;

  public mapStyles = [
    {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
      ]
    }
  ];
  public buttonTitle = '';
  public buttonColor = '';
  allowCheckIn: boolean = false;
  latitude: number;
  longitude: number;
  private photoBlob!: any;
  public photoUri!: string;
  @Input() userLocation!: LocationEntity;
  slideOptsTwo = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 20
  };
  constructor(
    private mapsAPILoader: MapsAPILoader,
    public route: ActivatedRoute,
    private trailService: TrailsService,
    public modalCtrl: ModalController,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AngularFireAnalytics,
    private geolocation: Geolocation,
    private storage: Storage,
    public events: Events,
    private distanceUtils: DistanceUtilsService,
    private translateService: TranslateService,
  ) {
    this.latitude = environment.cms.main_coordinates.latitude;
    this.longitude = environment.cms.main_coordinates.longitude;
    // this.trackLocation();
              // this.changeDetectorRef.detectChanges();
   }

   ionViewDidEnter() {
    // setTimeout(() => {
    //   this.changeDetectorRef.detectChanges();
    // }, 100);
  }
  async ngOnInit() {
    console.log(this.listings);
    this.latitude = this.listings[0].Latitude;
    this.longitude = this.listings[0].Longitude;
    this.listings[0].zIndex = 1000;
    if (!this.fitBound) {
      this.windowH = window.innerHeight-300;
      this.mapH = window.innerHeight-50;
      this.listings[0].IconWidth = 45;
      this.listings[0].IconHeight = 60;
      }
    else {
      this.windowH = window.innerHeight-580;
      this.mapH = window.innerHeight-440;
        if (this.customIcon.length > 0)
        this.listings[0].Icon = this.customIcon;
    }
    this.previousIndex = 0;
    this.listings[0].Distance = await this.distanceUtils.calculateDistance(this.listings[0].Latitude, this.listings[0].Longitude, 'miles');
    console.log(this.listings[0])
    // Object.values(this.listings).forEach(value => {
    //   this.getDistanceBetween(value.Latitude,value.Longitude);
    // });
    this.validateLocation();
  }


  private async validateLocation() {
    try {
        // watch.subscribe(async (location: Geoposition) => {
        if (this.userLocation) {
          // const userLocation = { Latitude: this.currentLocation.Latitude, Longitude: this.currentLocation.Longitude };
          const geofenceLatitude = +this.listings[0].Latitude;// this.route.snapshot.queryParams.latitude;
          const geofenceLongitude = +this.listings[0].Longitude;//+this.route.snapshot.queryParams.longitude;
          const geofenceRadius = this.route.snapshot.queryParams.radius ? this.route.snapshot.queryParams.radius : 100;
          const distanceInMeters = this.distanceInMeters(this.userLocation.latitude, this.userLocation.longitude, geofenceLatitude, geofenceLongitude);
          console.log(distanceInMeters)
          const trailId = +this.route.snapshot.queryParams.trailId;
          if (distanceInMeters <= geofenceRadius) {
            this.allowCheckIn = true;
            this.isLoading = false;
            const roundedMeters = Math.floor(distanceInMeters);
            const message = await this.translateService.instant("alerts.outofrange", {mi: `${this.listings[0].Distance}`})
            // const message = `You are out of range by ${Math.floor(roundedMeters * 3.281)} ft. (${roundedMeters} meters)`;
            await this.alertService.presentInfoAlert(message, 'Check In Error', [{ text: await this.translateService.instant("alerts.dismiss") }]);
          } else {
            this.isLoading = false;
            const roundedMeters = Math.floor(distanceInMeters);
            const message = await this.translateService.instant("alerts.outofrange", {mi: `${this.listings[0].Distance}`})
            // const message = `You are out of range by ${Math.floor(roundedMeters * 3.281)} ft. (${roundedMeters} meters)`;
            await this.alertService.presentInfoAlert(message, 'Check In Error', [{ text: await this.translateService.instant("alerts.dismiss") }]);
            this.translateService.get('login.email').subscribe(t => {
              console.log(t);
            });
            this.events.publish('expandMap', {data: { user: this.userLocation, latitude: geofenceLatitude, longitude: geofenceLongitude, radius: geofenceRadius} });
            //throw new RangeError(message);
          }
        } else {
          this.alertService.presentInfoAlert('Error location-permission-denied', 'Check In Error', [{ text: await this.translateService.instant("alerts.dismiss") }]);
          //throw new EvalError('location-permission-denied');
        }
      // }),((error) => {
      //   console.error('Error getting location', error);
      //   this.alertService.presentInfoAlert('Error getting location', 'Check In Error', [{ text: await this.translateService.instant("alerts.dismiss") }]);
      //   //this.isLocationAvailable = false;
      // });

    } catch (error) {
      throw error;
    }
  }

  goBack() {
    window.history.back();
  }

  getCurrentLocation(finishCheckin?: Boolean) {
    console.log("getCurrentLocation", moment().toDate());
    try {
      (this.geolocationSubscription = this.geolocation
        .watchPosition({ enableHighAccuracy: true, timeout: 2000 })
        .subscribe(async (location: any) => {
          console.log(location);
          // if (location.coords) {
            if (location.code == 3 || location.code == 2) {
              const lastLocation = await this.storage.get("lastLocation");
              this.userLocation = lastLocation;
            } else {
              this.userLocation = {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              };
              this.storage.set("lastLocation", this.userLocation);
            }
            if (finishCheckin) this.validateLocation();
          // } else {
          //   this.alertService.presentInfoAlert(
          //     "Error location-permission-denied",
          //     "Check In Error",
          //     [{ text: "Dismiss" }]
          //   );
          //   //throw new EvalError('location-permission-denied');
          // }
        })),
        (error) => {
          console.error("Error getting location", error);
          this.alertService.presentInfoAlert(
            "Error getting location",
            "Check In Error",
            [{ text: "Dismiss" }]
          );
          //this.isLocationAvailable = false;
        };
    } catch (error) {
      throw error;
    }
  }
  onFileChange(evt: any) {
    const reader = new FileReader();
    if (evt.target.files && evt.target.files.length > 0) {
      this.photoBlob = evt.target.files[0];
      reader.readAsDataURL(this.photoBlob);
      reader.onload = (() => { this.photoUri = reader.result as string; }).bind(this);
    }
  }

  async presentModalPrgressTrail(award?: any) {
    const modal = await this.modalCtrl.create({
      component: BeerOMeterComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        badge: award,
        trailId: this.trailId
      }
    });
    await modal.present();
    // return await modal.present();
    modal.onDidDismiss().then(async (detail: any) => {
      if (detail.data.continue){
      }
      if (detail !== null) {
        //console.log('The result:', detail.data);
      }
   });
  }


  async presentModal(award) {
    const modal = await this.modalCtrl.create({
      component: TrailCompletedComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        badge: award,
        trailId: this.trailId
      }
    });
    await modal.present();
    // return await modal.present();
    modal.onDidDismiss().then(async (detail: any) => {
      this.modalCtrl.dismiss();
   });
  }

  private distanceInMeters(
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ): number {
    console.log(x1)
    console.log(y1)
    console.log(x2)
    console.log(y2)
    let result = 0;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE = 1609.34;

    if (x1 === x2 && y1 === y2) {
      result = 0;
    } else {
      // Calculating Distance between Points
      const lt1 = x1 / RADIANS;
      const lg1 = y1 / RADIANS;
      const lt2 = x2 / RADIANS;
      const lg2 = y2 / RADIANS;

      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result =
        3958.8 *
        METRES_IN_MILE *
        Math.acos(
          Math.sin(lt1) * Math.sin(lt2) +
            Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1)
        );
    }
    return result;
  }

  async takePicture() {
    this.cameraInputFile.nativeElement.click();
  }

  ngOnDestroy() {
    // this.geolocationSubscription.unsubscribe();
  }
  async trackLocation() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    const watch = this.geolocation
      .watchPosition(options);
      // watch.subscribe((resp: Geoposition) => {
      // console.log('resp',resp)
      //this.isLocationAvailable = true;
      if (this.listings) {
        // compute distance and bind it to each listing
        // this.userLocation = { latitude: this.currentLocation.Latitude, longitude: this.currentLocation.Longitude };
        this.setDistance();
        this.isLoading = false;
      } else {
        console.error('listings not available yet');
      }
    // }),((error) => {
    //   this.isLoading = false;
    //   console.error('Error getting location', error);
    //   //this.isLocationAvailable = false;
    // });
  }
  metersToMi(meters: number): number {
    return meters * 0.000621371;
  }

  setDistance() {
    this.mapsAPILoader.load().then(() => {

      var location = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
      this.listings.forEach(item => {
        if (item.Latitude && item.Longitude) {
          const listingLocation = new google.maps.LatLng(item.Latitude, item.Longitude);
          if (google.maps.geometry) {
            const distanceInM = google.maps.geometry.spherical.computeDistanceBetween(location, listingLocation);
            item.Distance = parseFloat(
              this.metersToMi(Math.round(distanceInM * 100) / 100).toFixed(2)
            );
            }
        }
      });
      this.listings.sort(function (a, b) {
        return a.Distance - b.Distance;
      });

    }
    );

  }

  getDistanceBetween(lat1,long1,lat2,long2){
    var from = new google.maps.LatLng(lat1,long1);
    var to = new google.maps.LatLng(lat2,long2);

    if(google.maps.geometry.spherical.computeDistanceBetween(from,to) <= this.radius){
      console.log('Radius',this.radius);
      console.log('Distance Between',google.maps.geometry.spherical.computeDistanceBetween(
        from,to
      ));
      return true;
    }else{
      return false;
    }
  }

}
