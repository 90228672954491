import { Component, OnInit, OnDestroy } from "@angular/core";

import { CMSDataControllerService } from "@app/core/data/controller/cmsdata-controller.service";
import { CMSEvent } from "@app/core/data/CMSEvent";
import { environment } from "@env/environment";
import moment from "moment";
import { SubCategory } from "@app/core/data/SubCategory";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
declare function require(name: string);

import { Storage } from "@ionic/storage";
@Component({
  selector: "app-change-region-row",
  templateUrl: require("template/" +
    environment.template +
    "/html/change-region-row.component.html"),
  styles: [
    require("template/" +
      environment.template +
      "/styles/change-region-row.component.scss"),
  ],
})
export class ChangeRegionRowComponent implements OnInit {
  currentRegion: any;
  regions: any;
  constructor(
    private router: Router,
    public storage: Storage,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dataController: CMSDataControllerService
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.currentRegion = await this.storage.get("activeRegion");
      const suggestedRegion = await this.storage.get("suggestedRegion");
      const userRegionSelected = await this.storage.get("regionSelectedByUser");
      this.dataController
        .getPages({
          domainId: environment.cms.domainId,
          parentPageId: environment.regions.regionsPageId,
        })
        .subscribe(
          (list) => {
            this.regions = list
              .filter((item) => {
                return item.active;
              })
              .sort((a, b) => {
                if (a.SortOrder > b.SortOrder) {
                  return 1;
                } else if (a.SortOrder < b.SortOrder) {
                  return -1;
                }
                return 0;
              });
            if (
              suggestedRegion &&
              (!userRegionSelected || !this.currentRegion)
            ) {
              const activeRegion = this.regions.find(
                (obj) =>
                  obj.PageName.toLowerCase() ==
                  suggestedRegion.name.toLowerCase()
              );
              this.currentRegion = activeRegion;
              this.storage.set("activeRegion", activeRegion);
            }
          },
          (err) => {
            console.error(err);
          }
        );
    });
  }
}
