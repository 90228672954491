import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
declare function require(name:string);

@Component({
  selector: 'app-untappd',
  templateUrl: require('template/' + environment.template + '/html/untappd.component.html'),
  styles: [require('template/' + environment.template + '/styles/untappd.component.scss')]
})
export class UntappdComponent implements OnInit {

  public environment = environment;
  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {}

  openUntappdAndroid() {
    window.open(`https://play.google.com/store/apps/details?id=com.untappdllc.app`, '_blank');
  }

  openUntappdiOS() {
    window.open(`https://apps.apple.com/us/app/untappd-discover-beer/id449141888`, '_blank');
  }

}
