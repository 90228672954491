import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalAwardsComponent } from '@app/codebase/modal-awards/modal-awards.component';
import { TrailsService } from '@app/shared/services/trails.service';
import { environment } from '@env/environment';

import { ModalController } from '@ionic/angular';
declare function require(name:string);

@Component({
  selector: 'app-trail-completed',
  templateUrl: require('template/' + environment.template + '/html/trail-completed.component.html'),
  styles: [require('template/' + environment.template + '/styles/trail-completed.component.scss')]
})
export class TrailCompletedComponent implements OnInit {

  public environment = environment;  @Input() badge?: string;
  @Input() trailId?: any;
  @Input() task?: any;
  trail: any;
  constructor(
    private router: Router,
    private trailService: TrailsService,
    public  modalCtrlr: ModalController,
    public modalController: ModalController) { }

  async ngOnInit() {
    console.log(this.badge)

    await this.trailService.getInteractiveTrails().then(val => {
      //console.log(val.find(res => res.Id == this.trailId))
      this.trail = val.find(res => res.Id == this.trailId)
      console.log(this.trail)
      //this.mapIcon = this.trail.Image;
    })
   }

  public async dismiss() {
    this.router.navigate(['/tabs/trails']);
    return await this.modalController.dismiss();
  }

  async onShareTap() {
    let myNavigator: any;
    myNavigator = window.navigator;
    if (myNavigator && myNavigator.share) {
      await myNavigator.share({
        title: 'Super excited!',
        text: `I just got the ${this.trail.Title} on the TrueOmni app! #trueomni`,
        url: 'https://staging-trueomni.web.app/tabs/home',
      });
    } else {
      window.alert('Can not share at this moment');
    }
  }

  removeHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  async openAwards() {
    const modal = await this.modalCtrlr.create({
      component: ModalAwardsComponent,
      cssClass: 'my-custom-class-awards',
    });
    await modal.present();
    // return await modal.present();
    modal.onDidDismiss().then(async (detail: any) => {
      if (detail.data.continue){
        // await this.presentModal2();
      }
      if (detail !== null) {
        console.log('The result:', detail.data);
      }
   });
  }

  async myDismiss(next :boolean) {
    this.router.navigate(['/tabs/trails']);
    return await this.modalController.dismiss();
  }

}
