import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrailsService } from '@app/shared/services/trails.service';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

@Component({
  selector: 'app-modal-awards',
  templateUrl: require('template/' + environment.template + '/html/modal-awards.component.html'),
  styles: [require('template/' + environment.template + '/styles/modal-awards.component.scss')]

})
export class ModalAwardsComponent implements OnInit {

  public environment = environment;
  leaderboard: any;
  public language: string;
  awards: any  = [];
  pins: any = [];
  constructor(
    private trailsService: TrailsService,
    private router: Router,
    private modalCtrol: ModalController,
    private translateService: TranslateService,) {
      this.language = this.translateService.currentLang;
      this.pins = [
        {
          id: 1,
          quantityForGet: 25,
          image: `themes/{{environment.theme.name}}/assets/pins/pinOne${this.language || "eng"}.png`,
          obtained: false,
          percentage: 0,
        },
        {
          id: 2,
          quantityForGet: 50,
          image: `themes/{{environment.theme.name}}/assets/pins/pinTwo${this.language || "eng"}.png`,
          obtained: false,
          percentage: 0,
        },
        {
          id: 3,
          quantityForGet: 75,
          image: `themes/{{environment.theme.name}}/assets/pins/pinTree${this.language || "eng"}.png`,
          obtained: false,
          percentage: 0,
        },
      ];
     }

  async ngOnInit() {
    await this.trailsService.getLeadership().then(value => {
      this.leaderboard = value;
      this.awards = this.leaderboard.AwardListResult;
      this.awards[0].TrailCompleted = true;
      switch (this.leaderboard.Pins) {
        case 3:
          this.pins[0].obtained = true;
          this.pins[1].obtained = true;
          this.pins[2].obtained = true;
          break;
        case 2:
          this.pins[0].obtained = true;
          this.pins[1].obtained = true;
          break;
        case 1:
          this.pins[0].obtained = true;
          break;
        default:
          break;
      }
    })
  }

  goToTrails(resp: boolean){
    if (resp){
      this.router.navigate(["tabs/trails"]);
      this.modalCtrol.dismiss(false);
    }else{
      this.modalCtrol.dismiss(false);
    }
  }

}
