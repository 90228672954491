import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CMSPage } from "@app/core/data/CMSPage";
import { CMSDataControllerService } from "@app/core/data/controller/cmsdata-controller.service";
import { FavoritesService } from "@app/core/services/favorites.service";
import { IFrameModalPage } from "@app/i-frame-modal/i-frame-modal.page";
import { RedirectService } from "@app/shared/services/redirect.service";
import { environment } from "@env/environment";
import { Events, ModalController } from "@ionic/angular";
import { Storage } from "@ionic/storage";

@Component({
  selector: "app-region-childrens",
  templateUrl: "./region-childrens.component.html",
  styleUrls: ["./region-childrens.component.scss"],
})
export class RegionChildrensComponent implements OnInit {
  languageSelected: any;
  activeRegion: any;
  public environment = environment;
  childrens: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private favoritesService: FavoritesService,
    private redirect: RedirectService,
    private modalCtrlr: ModalController,
    public events: Events,
    private dataController: CMSDataControllerService,
    public storage: Storage
  ) {
    this.events.subscribe("languageChanges", (params) => {
      this.dataController
        .getPages(
          {
            domainId: environment.cms.domainId,
            parentPageId: environment.homeOptions.optionPageId,
          },
          params.value
        )
        .subscribe((pages) => {
          this.childrens = pages.filter((obj) => obj.active);
        });
    });
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.activeRegion = await this.storage.get("activeRegion");
      if (!this.activeRegion) {
        this.activeRegion = environment.defaultRegion;
        await this.storage.set("activeRegion", this.activeRegion);
      }
      this.languageSelected = await this.storage.get("language");
      this.dataController
        .getPages(
          {
            domainId: environment.cms.domainId,
            parentPageId: environment.homeOptions.optionPageId,
          },
          this.languageSelected
        )
        .subscribe((pages) => {
          this.childrens = pages.filter((obj) => obj.active);
        });
    });
  }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  async onItemClick(item: CMSPage) {
    const title = item.PageName || item.name;
    let link = item.rssLink || item.linkTo;
    if ((item.rssLink && item.rssLink.includes("content")) || !item.rssLink) {
      this.router.navigate(["/tabs/to-do"], {
        queryParams: {
          t: title,
          parentPageId: item.PageID,
          pageData: item.PageData,
        },
      });
    } else if (link.includes("http")) {
      // this.iab.create(link,  "_self", "popup=yes, toolbarposition=top,closebuttoncaption=Close");
      this.ticketsClick(link);
    } else if (link.includes("trails://")) {
      this.router.navigate(["/tabs/trails"]);
    } else if (link.includes("events://")) {
      this.router.navigate(["/tabs/events"], {
        queryParams: { t: title, rssLink: link, parentPageId: item.PageID },
      });
    } else if (link == "category://?categoryid=map") {
      this.router.navigate(["/tabs/listings"], {
        queryParams: { showMap: true },
      });
    } else if (link == "passport://") {
      this.router.navigate(["/tabs/passport"]);
    } else if (link == "itinerarybuilder://") {
      let favoriteListings = (
        await this.favoritesService.getFavoritesListings()
      ).map((obj) => obj.ListingID);
      let favoriteEvents = (
        await this.favoritesService.getFavoritesEvents()
      ).map((obj) => obj.EventID);
      this.redirect.onRestrictedUrlAccessTry(
        "/tabs/itineraries",
        {
          t: item.PageName,
          parentPageId: item.PageID,
          fl: favoriteListings.toString(),
          fe: favoriteEvents.toString(),
        },
        true
      );
    } else if (link == "socialwall://") {
      this.router.navigate(["/tabs/social-wall"], {
        queryParams: { t: item.PageName },
      });
    } else if (item.PageName == "Digital Brochure") {
      this.router.navigate(["/tabs/digital-brochure"]);
    } else if (item.PageName == "Itinerary Builder") {
      this.router.navigateByUrl("itineraries", {
        relativeTo: this.route,
        queryParams: { t: "Itineraries", parentPageId: item.PageID },
      });
    } else if (item.PageName == "All in One Ticket") {
      this.router.navigate(["/passport"]);
    } else {
      switch (link.split("?")[0]) {
        case "dine://":
          this.router.navigate(["/tabs/stay"], {
            queryParams: { t: title, parentPageId: item.PageID },
          });
          break;
        case "customrows://":
          this.router.navigate(["/tabs/category-rows"], {
            queryParams: { t: title, parentPageId: item.PageID },
          });
          break;
        case "map://":
        case "maps://":
          this.router.navigate(["/tabs/listings"], {
            queryParams: { t: title, showMap: true, parentPageId: item.PageID },
          });
          break;
        case "trails://":
          this.router.navigate(["/tabs/to-do"], {
            queryParams: {
              t: title,
              deepLink: link,
              parentPageId: item.PageID,
            },
          });
          break;
        case "deals://":
          this.router.navigateByUrl("/tabs/deals", {
            relativeTo: this.route,
            queryParams: { t: title },
          });
          break;
        case "category://":
          this.router.navigate(["/tabs/category"], {
            queryParams: { t: title, parentPageId: item.PageID },
          });
          break;
        case "listings://":
          this.router.navigate(["/tabs/listings"], {
            queryParams: { t: title, rssLink: link, parentPageId: item.PageID },
          });
          break;

        default:
          this.router.navigate(["/tabs/stay"], {
            queryParams: {
              t: item.PageName,
              rssLink: link,
              parentPageId: item.PageID,
            },
          });
          break;
      }
    }
  }

  async ticketsClick(link: string) {
    const modal = await this.modalCtrlr.create({
      component: IFrameModalPage,
      componentProps: {
        url: link,
      },
    });
    await modal.present();
  }
}
