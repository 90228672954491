import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-initialpopup',
  templateUrl: './initialpopup.component.html',
  styleUrls: ['./initialpopup.component.scss'],
})
export class InitialpopupComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  goBack(){
    this.modalCtrl.dismiss();
  }
}
