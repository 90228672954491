import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BatutaCountry } from '@app/codebase/sign-up/interfaces/batuta-country';
import { BatutaState } from '@app/codebase/sign-up/interfaces/batuta-state';
import { BatutaService } from '@app/codebase/sign-up/services/batuta.service';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';

import { LoadingController, ModalController, PickerController } from '@ionic/angular';
import { PickerOptions } from '@ionic/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
})
export class ModalFormComponent implements OnInit {

  @Input() title?: string;
  @Input() message?: string;
  @Input() buttons?: { title: string, value?: string }[];
  public registerForm: FormGroup;
  public countries: any[] = [];
  public states: BatutaState[] = [];
  public selectedCountry: any = { text: 'Country', value: 'default' };
  public selectedState: BatutaState = { text: 'State', value: 'default' };

  public isButtonStateDisabled = true;
  private defaultCountry: any = { text: 'Country', value: 'default' };
  private defaultState: BatutaState = { text: 'State', value: 'default' };
  customAlertOptions: any = {
    header: 'Select your country'
  };

  constructor(
    private modal: ModalController,
    private batuta: BatutaService,
    private pickerController: PickerController,
    private dataController: CMSDataControllerService,
    private loadingController: LoadingController,
    private loading: LoadingService,
    private fb: FormBuilder,
  ) {
    this.countries = this.batuta.getCountries();
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]]
    });
  }

  async setStates(data){
    this.states = [];
    this.registerForm.controls.state.patchValue(null);
    await this.loading.presentLoading();
    let countryName = this.countries.find(obj => `${obj.code} - ${obj.name}`.toLowerCase() == data.detail.value.toLowerCase()).name
    this.states = this.batuta.getStates(countryName) as any;
    this.loading.dismiss();
    if (this.states.length > 1) {
      this.isButtonStateDisabled = false;
    } else {
      this.selectedState = this.defaultState;
      this.isButtonStateDisabled = true;
    }
  }
  private removeAllAddedAttributesToArray(list: any[]) {
    list.forEach((data: any) => {
      if (data.duration) {
        delete data.transform;
        delete data.duration;
        delete data.selected;
      }
    });
  }

  async onButtonClick(evt: any) {
    return this.modal.dismiss({
      value: evt
    });
  }

  async register(){
    let form = this.registerForm.value;
    this.dataController.saveForm(form).then(resp =>{
      if (resp[0].Status == "SUCCESS"){
        this.onButtonClick('SUCCESS');
      }
    })
  }
}
