import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { CoreAuthLibClientService } from '@app/core-auth-lib/services/core-auth-lib-client.service';
import { Injectable } from '@angular/core';
import { Task } from '@app/codebase/trails/models/task';
import { Trail } from '@app/codebase/trails/models/trail';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { catchError, take } from 'rxjs/operators';
import { CodebaseTranslateService } from '@app/services/codebase-translate.service';
import { Storage } from '@ionic/storage';
import { environment } from '@env/environment';
import { Listing } from '@app/core/data/Listing';
import { Network } from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root'
})
export class ScavengerHuntService {

  public trailCompletionPercentage$: BehaviorSubject<number>;
  public completedTasks$: BehaviorSubject<Task[]>;
  public incompleteTasks$: BehaviorSubject<Task[]>;
  public currentTrailId: number;
  private incompleteTasksList: Task[] = [];
  private completedTasksList: Task[] = [];
  private baseUrl = 'https://trailsapi.mobimanage.com';
  //private baseUrl = 'http://localhost:62262';

  constructor(
    private http: HttpClient,
    private codebaseTranslateService: CodebaseTranslateService,
    private userApi: UsersApiService,
    private network: Network,
    private storage: Storage,
    private coreAuth: CoreAuthLibClientService
  ) {
    this.trailCompletionPercentage$ = new BehaviorSubject(0);
    this.completedTasks$ = new BehaviorSubject([]); // of(this.completedTasks);
    this.incompleteTasks$ = new BehaviorSubject([]); // of(this.incompleteTasksList);
    // this.loadTrailAndTasks();
  }

  async newUserJoin() {
    try {
      await this.userApi.isLoggedIn();
      const trail = (await this.getInteractiveTrails(0, 1)).pop();
      await this.joinInteractiveTrail(trail.Id);
      const userTrail = (await this.getUserInteractiveTrails()).pop();
      this.currentTrailId = userTrail.Id;
      const tasks = await this.getInteractiveTrailTask(userTrail.Id);
      this.completedTasksList = tasks.filter(value => value.IsCompleted === true);
      this.incompleteTasksList = tasks.filter(value => value.IsCompleted === false);
      this.updateObservables();
    } catch (error) {
    }
  }

  async newUserJoinTrailId(trailId: number) {
    try {
      await this.userApi.isLoggedIn();
      const trail = (await this.getInteractiveTrails(0, 1)).pop();
      await this.joinInteractiveTrail(trailId);
      const userTrail = (await this.getUserInteractiveTrails()).pop();
      this.currentTrailId = userTrail.Id;
      const tasks = await this.getInteractiveTrailTask(userTrail.Id);
      this.completedTasksList = tasks.filter(value => value.IsCompleted === true);
      this.incompleteTasksList = tasks.filter(value => value.IsCompleted === false);
      this.updateObservables();
    } catch (error) {
    }
  }

  async loadTrailAndTasks() {
    try {
      const isLoggedIn = await this.userApi.isLoggedIn();
      let userTrail = (await this.getUserInteractiveTrails()).pop();
      if (userTrail === undefined && isLoggedIn) { // No user trail
        const trail = (await this.getInteractiveTrails(0, 1)).pop();
        if (trail !== undefined) {
          await this.joinInteractiveTrail(trail.Id);
          userTrail = (await this.getUserInteractiveTrails()).pop();
          this.currentTrailId = userTrail.Id;
          const tasks = await this.getInteractiveTrailTask(userTrail.Id);
          this.completedTasksList = tasks.filter(value => value.IsCompleted === true);
          this.incompleteTasksList = tasks.filter(value => value.IsCompleted === false);
          this.updateObservables();
        } else {
        }
      } else { // User trail found
        this.currentTrailId = userTrail.Id;
        const tasks = await this.getInteractiveTrailTask(userTrail.Id);
        this.completedTasksList = tasks.filter(value => value.IsCompleted === true);
        this.incompleteTasksList = tasks.filter(value => value.IsCompleted === false);
        this.updateObservables();
      }
    } catch (error) {
    }
  }

  async loadTrailAndTasksByTrailId(trailId: number) {
    try {
        this.currentTrailId = trailId;
        const tasks = await this.getInteractiveTrailTask(trailId);
        this.completedTasksList = tasks.filter(value => value.IsCompleted === true);
        this.incompleteTasksList = tasks.filter(value => value.IsCompleted === false);
        this.updateObservables();
        return tasks;
      // }
    } catch (error) {
    }
  }
  async loadAllTrailsAndTasks() {
    try {
      const isLoggedIn = await this.userApi.isLoggedIn();
      let userTrails = await this.getUserInteractiveTrails();
      var allTasks = [];
      userTrails.forEach(trail => {
        this.getInteractiveTrailTask(trail.Id).then(tasks => {
          tasks.forEach(task => {
            allTasks.push(task);
          });
        });

      });
      return allTasks;
    } catch (error) {
    }
  }

  async finishScanTask(trailId: number, taskId: number, answer: { Phrase: string, GeoPosition: { Latitude: number; Lotitude: number } }) {
    try {
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${trailId}/scans/${taskId}`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      return await this.http.put<{ IsScavengerHuntCompleted: boolean, IsTrailCompleted: boolean }>(tasksUrl, answer, {
        headers
      }).pipe(take(1)).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async finishQuestionTask(
    trailId: number, taskId: number, answer: { Answer: string, GeoPosition: { Latitude: number; Lotitude: number } }) {
    try {
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${trailId}/questions/${taskId}`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      return await this.http.put<{ IsScavengerHuntCompleted: boolean, IsTrailCompleted: boolean }>(tasksUrl, answer, {
        headers
      }).pipe(take(1)).toPromise();
    } catch (error) {
      throw error;
    }
  }

  //PUT /api/v1/me/trails/{trailId}/check-ins/{taskId}/{validateWithSnap}
  async finishCheckInTaskPhoto(taskId: number, phrase: string, fileBlob: any, position: { Latitude: number, Longitude: number }, trailId: number) {
    try {
      var myTrailId = this.currentTrailId;
      if (trailId)
        myTrailId = trailId;
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${myTrailId}/check-ins/${taskId}/true?trailId=${myTrailId}&taskId=${taskId}&validateWithSnap=true`;
      const token = await this.coreAuth.getToken();
      const headers = new HttpHeaders({
        Authorization: `${token.tokenType} ${token.accessToken}`,
        // 'Content-Type': 'multipart/form-data',
        'Content-Length': '5000000',
        Accept: 'application/json',
      });


      var fileToUpload: any = fileBlob;
      // if (typeof fileBlob === 'string') {
      //   fileToUpload = await this.http.get(fileBlob).pipe(take(1)).toPromise();
      // } else {
      //   fileToUpload = fileBlob;
      // }

      let bodyJson: any;
      if (position) {
        bodyJson = {
          "Latitude": position.Latitude,
          "Longitude": position.Longitude
        };
      } else {
        bodyJson = {
          "Latitude": 0,
          "Longitude": 0,
        };
      }

      const formData: FormData = new FormData();
        formData.append('Image', fileToUpload, fileToUpload.name);

        const jsonBlob = new Blob([JSON.stringify(bodyJson)], {
          type: 'application/json',
        });
        // formData.append('RegisterCheckIn', bodyJson);

      const results = await this.http.put<any>(tasksUrl, formData, {
        headers: headers
      }).pipe(take(1)).toPromise();
      await this.loadTrailAndTasksByTrailId(myTrailId);

      const completedTask = this.incompleteTasksList.filter(task => task.Id === taskId).pop();
      if (completedTask) {
        completedTask.IsCompleted = true;
        this.completedTasksList.push(completedTask);
        this.incompleteTasksList = this.incompleteTasksList.filter(task => task.Id !== taskId);
      }
      this.updateObservables();
      return results;
    } catch (error) {
      throw error;
    }
  }

  async finishCheckInTask(taskId: number, position: { Latitude: number, Longitude: number }, trailId: number) {
    try {
      const estadoRed = this.network.type;
      var myTrailId = this.currentTrailId;
      if (trailId)
        myTrailId = trailId;
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${myTrailId}/check-ins/${taskId}`;
      const token = await this.coreAuth.getToken();
      const headers = new HttpHeaders({
        Authorization: `${token.tokenType} ${token.accessToken}`,
        'Content-Type': 'application/json'
      });
      const results = await this.http.put<{ IsScavengerHuntCompleted: boolean, IsTrailCompleted: boolean }>(tasksUrl, position, {
        headers
      }).pipe(take(1)).toPromise();
      await this.loadTrailAndTasksByTrailId(myTrailId);

      const completedTask = this.incompleteTasksList.filter(task => task.Id === taskId).pop();
      if (completedTask) {
        completedTask.IsCompleted = true;
        this.completedTasksList.push(completedTask);
        this.incompleteTasksList = this.incompleteTasksList.filter(task => task.Id !== taskId);
      }
      this.updateObservables();
      return results;
    } catch (error) {
      throw error;
    }
  }

  async finishCheckInTaskOffline(taskId: number, position: { Latitude: number, Longitude: number }, trailId: number) {
    try {
      const estadoRed = this.network.type;
      var myTrailId = this.currentTrailId;
      if (trailId)
        myTrailId = trailId;
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${myTrailId}/check-ins/${taskId}`;
      const token = await this.coreAuth.getToken();
      const headers = new HttpHeaders({
        Authorization: `${token.tokenType} ${token.accessToken}`,
        'Content-Type': 'application/json'
      });
      await this.loadTrailAndTasksByTrailId(myTrailId);

      const completedTask = this.incompleteTasksList.filter(task => task.Id === taskId).pop();
      if (completedTask) {
        completedTask.IsCompleted = true;
        this.completedTasksList.push(completedTask);
        this.incompleteTasksList = this.incompleteTasksList.filter(task => task.Id !== taskId);
      }
      this.updateObservables();
      return this.http.put<{ IsScavengerHuntCompleted: boolean, IsTrailCompleted: boolean }>(tasksUrl, position, {
        headers
      }).pipe(
        catchError(this.error)
      );
      // return results;
    } catch (error) {
      throw error;
    }
  }

  async joinInteractiveTrail(trailId: number) {
    try {
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${trailId}/joining`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      return (await this.http.post<{ Id: number }>(tasksUrl, null, {
        headers
      }).pipe(take(1)).toPromise()).Id;
    } catch (error) {
      throw error;
    }
  }

  async getListingStatusChecked(listingId: number) {
    try {
      // return this.storage.get('tasks').then(resp => {return resp})
      const tasksUrl = `${this.baseUrl}/api/v1/me/listing/${listingId}`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      return (await this.http.get<{ IsCompleted: boolean }>(tasksUrl, {headers}).pipe(take(1)).toPromise());
    } catch (error) {
      throw error;
    }
  }

  async joinAllInteractiveTrail() {
    try {
      const tasksUrl = `${this.baseUrl}/api/v1/me/joinall`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      return (await this.http.post<{ Id: number }>(tasksUrl, null, {
        headers
      }).pipe(take(1)).toPromise());
    } catch (error) {
      throw error;
    }
  }

  async getInteractiveTrailTask(trailId: number) {
    try {
      const tasksUrl = `${this.baseUrl}/api/v1/me/trails/${trailId}/tasks`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<{ Tasks: Task[] }>(tasksUrl, { headers }).pipe(take(1)).toPromise();
      return response.Tasks;
    } catch (error) {
      throw error;
    }
  }

  async getLeadership() {
    try {
      const leaderboardUrl = `${this.baseUrl}/api/v1/me/trails/leaderboard`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<{ Tasks: Task[] }>(leaderboardUrl, { headers }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCompletedTasks() {
    try {
      const completedTaskUrl = `${this.baseUrl}/api/v1/me/completedtasks`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<any>(completedTaskUrl, { headers }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAwards() {
    try {
      const leaderboardUrl = `${this.baseUrl}/api/v1/trails/badgeslist`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<{ Tasks: Task[] }>(leaderboardUrl, { headers }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAwars(trailId?: number) {
    try {
      const leaderboardUrl = `${this.baseUrl}/api/v1/me/trails/${trailId}/awards`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<{ Tasks: Task[] }>(leaderboardUrl, { headers }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }//api/v1/trailsrank

  async getRank(startDate: string, endDate: string, userId: number, trailsRank: boolean) {
    try {
      const rankUrl = `${this.baseUrl}/api/v1/trailsrank?userId=${userId}&startDate=${startDate}&endDate=${endDate}&trailsRank=${trailsRank}`;
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const response = await this.http.get<{ Tasks: Task[] }>(rankUrl, { headers }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }//api/v1/trailsrank

  async getInteractiveTrails(pageSize?: number, pageNumber?: number) {
    try {
      const huntsUrl = `${this.baseUrl}/api/v1/trails?language=${this.codebaseTranslateService.language || "en"}`;
      return (await this.makeTrailsRequest(huntsUrl, pageSize, pageNumber)).Trails;
    } catch (error) {
      throw error;
    }
  }

  async getUserInteractiveTrails(pageSize?: number, pageNumber?: number) {
    try {
      const huntsUrl = `${this.baseUrl}/api/v1/me/trails?language=${this.codebaseTranslateService.language || "en"}`;
      return (await this.makeTrailsRequest(huntsUrl, pageSize, pageNumber)).Trails;
    } catch (error) {
      throw error;
    }
  }

  private updateObservables() {
    this.completedTasks$.next(this.completedTasksList);
    this.incompleteTasks$.next(this.incompleteTasksList);
    if (this.incompleteTasksList.length === 0) {
      this.trailCompletionPercentage$.next(100);
    } else if (this.completedTasksList.length === 0) {
      this.trailCompletionPercentage$.next(0);
    } else {
      const totalTasksCount = this.completedTasksList.length + this.incompleteTasksList.length;
      const incompleteTasksCount = this.completedTasksList.length;
      const percentage = Math.round((incompleteTasksCount * 100) / totalTasksCount);
      this.trailCompletionPercentage$.next(percentage);
    }
  }

  private async  makeTrailsRequest(url: string, pageSize?: number, pageNumber?: number) {
    try {
      const token = await this.coreAuth.getToken();
      const headers = this.requestHeader(token);
      const paramDict: { [key: string]: string } = {};
      if (pageSize) {
        paramDict.pageSize = `${pageSize}`;
      }
      if (pageNumber) {
        paramDict.pageNumber = `${pageNumber}`;
      }
      const params = new HttpParams({
        fromObject: paramDict
      });
      return await this.http.get<{ Trails: Trail[] }>(url, { headers, params }).pipe(take(1)).toPromise();
    } catch (error) {
      throw error;
    }
  }

  private requestHeader(token: { accessToken: string, tokenType: string }) {
    const headers = new HttpHeaders({
      Authorization: `${token.tokenType} ${token.accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }
   // Handle Errors
   error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.error;
    }
    return throwError(errorMessage);
  }
}
