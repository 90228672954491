import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Listing } from "@app/core/data/Listing";
import { FavoritesService } from "@app/core/services/favorites.service";
import { UsersApiService } from "@app/core/users-api/users-api.service";
import { ModalController, NavController } from "@ionic/angular";
import { GenericAlertComponent } from "../generic-alert/generic-alert.component";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { Storage } from "@ionic/storage";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@env/environment";
declare function require(name:string);

@Component({
  selector: "app-listing-cell-tnt",
  templateUrl: require('template/' + environment.template + '/html/listing-cell-tnt.component.html'),
  styles: [require('template/' + environment.template + '/styles/listing-cell-tnt.component.scss')]
})

export class ListingCellTntComponent implements OnInit {

  public environment = environment;  @Input() public listingTitle?: string;
  @Input() public listingId?: string;
  @Input() listing!: Listing;
  @Input() public subcategoryTitle?: string;
  @Input() public image?: string;
  @Input() public distance?: any;

  language = this.translate.currentLang;
  constructor(
    private userApi: UsersApiService,
    private router: Router,
    private analyticsService: AngularFireAnalytics,
    public storage: Storage,
    public translate: TranslateService,
    private navCtrl: NavController,
    private modalCtrlr: ModalController,
    private favorites: FavoritesService
  ) {
    // storage.get('language').then(lang => {
    //   console.log(lang)
    //   translate.setDefaultLang(lang || 'en')
    //   this.language = lang
    // })
  }

  ngOnInit() {}

  async onListingItineraryClick() {
    console.log(this.listing);

    this.userApi.isLoggedIn().then(async (loggedIn) => {
      if (!this.listing.Favorite) {
        if (loggedIn) {
          this.favorites
            .isFavorite(this.listing)
            .then((added) => {
              if (added) {
                // open itinerary
                // this.router.navigateByUrl('itineraries', {
                //   relativeTo: this.route,
                //   queryParams: { t: 'Itineraries' }
                // });
              } else {
                if (this.listing) {
                  this.analyticsService.logEvent("itinerary_added", {
                    content_type: "itinerary_added",
                    content_id: this.listing.ListingID,
                    item_name: this.listing.Company,
                    items: [{ name: this.listing.Company }],
                  });
                }

                // add to itinerary
                this.favorites
                  .favorite(this.listing)
                  .then((status) => {
                    if (status) {
                      // this.itineraryButtonText = 'Open itinerary';
                    } else {
                      console.log(`>>> favorite.then: ${status}`);
                      // this.itineraryButtonText = 'Add to itinerary';
                    }
                  })
                  .catch((err) => {
                    console.log(`>>> favorite.catch: ${err}`);
                    // this.itineraryButtonText = 'Add to itinerary';
                  });
              }
            })
            .catch((err) => {
              console.log(`>>> isFavoriteListing.catch: ${err}`);
            });
        } else {
          console.log(">>> isLoggedIn: loggedIn: " + loggedIn);
          await this.showLoginPopup();
        }
        this.listing.Favorite = !this.listing.Favorite;
      } else {
        this.userApi
          .isLoggedIn()
          .then(async (loggedIn) => {
            if (loggedIn) {
              this.favorites
                .unfavorite(this.listing.ListingID)
                .then((added) => {
                  if (added) {
                    // open itinerary
                    // this.router.navigateByUrl('itineraries', {
                    //   relativeTo: this.route,
                    //   queryParams: { t: 'Itineraries' }
                    // });
                  } else {
                    if (this.listing) {
                      this.analyticsService.logEvent("itinerary_removed", {
                        content_type: "itinerary_removed",
                        content_id: this.listing.ListingID,
                        item_name: this.listing.Company,
                        items: [{ name: this.listing.Company }],
                      });
                    }

                    // add to itinerary
                    this.favorites
                      .unfavorite(this.listing.ListingID)
                      .then((status) => {
                        if (status) {
                          // this.itineraryButtonText = 'Open itinerary';
                        } else {
                          console.log(`>>> favorite.then: ${status}`);
                          // this.itineraryButtonText = 'Add to itinerary';
                        }
                      })
                      .catch((err) => {
                        console.log(`>>> favorite.catch: ${err}`);
                        // this.itineraryButtonText = 'Add to itinerary';
                      });
                  }
                })
                .catch((err) => {
                  console.log(`>>> isFavoriteListing.catch: ${err}`);
                });
            } else {
              console.log(">>> isLoggedIn: loggedIn: " + loggedIn);
              await this.showLoginPopup();
            }
            this.listing.Favorite = !this.listing.Favorite;
          })
          .catch(async (err) => {
            console.log(">>> isLoggedIn: " + err);
            await this.showLoginPopup();
          });
      }
    });
  }

  getFavorite(listing) {
    return this.favorites.isFavoriteListing(listing.ListingID).then((res) => {
      return res;
    });
  }

  async showLoginPopup() {
    // this.translateService.languageChange(this.homePage.languageSelected.val);
    const modal = await this.modalCtrlr.create({
      cssClass: 'my-custom-class',
      component: GenericAlertComponent,
      componentProps: {
        title: this.translate.instant('MODAL_LOGIN.TITLE'),
        message: this.translate.instant('MODAL_LOGIN.SUBTITLE'),
        buttons: [
          {
            title: this.translate.instant('MODAL_LOGIN.BTN_LOGIN'),
            value: 'login'
          }, {
            title: this.translate.instant('MODAL_LOGIN.BTN_CANCEL'),
            value: 'cancel'
          }
        ]
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.value === 'login') {
      return this.navCtrl.navigateRoot(['/login'], { queryParams: { redirectUrl: this.router.url } });
    } else {
      return '';
    }
  }

  async onOpenListingClick(listing: Listing) {
    this.analyticsService.logEvent("popular_listing_from_list_view", {
      content_type: "popular_listing_from_list_view",
      content_id: listing.ListingID,
      item_name: listing.Company,
      items: [{ name: listing.Company }],
    });
    this.router.navigate(["/tabs/listings", listing.ListingID], {});
  }
}
