import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-untappd',
  templateUrl: './untappd.component.html',
  styleUrls: ['./untappd.component.scss'],
})
export class UntappdComponent implements OnInit {

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {}

  openUntappdAndroid() {
    window.open(`https://play.google.com/store/apps/details?id=com.untappdllc.app`, '_blank');
  }

  openUntappdiOS() {
    window.open(`https://apps.apple.com/us/app/untappd-discover-beer/id449141888`, '_blank');
  }

}
