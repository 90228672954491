import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-language-popover',
  templateUrl: './language-popover.component.html',
  styleUrls: ['./language-popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {
  @Input() languages: any;
  @Input() selected: any;
  public environment = environment;
  constructor(
    private popoverController: PopoverController,) { }

  ngOnInit() {
    console.log(this.selected)
    console.log(this.languages)
    this.languages.map(obj => {
      obj.value == this.selected.value ? obj.selected = true : obj.selected = false;
      return obj;
    })
    // this.languages[idx].selected = true;
  }

  openLanguageSelector(value){
    this.popoverController.dismiss(value)
  }
}
