import { Injectable } from "@angular/core";
import moment from "moment";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { Subscription } from "rxjs";
import { Storage } from "@ionic/storage";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class DistanceUtilsService {
  userLocation: any;
  constructor(private geolocation: Geolocation, private storage: Storage) {}

  async calculateDistance(
    lat1: number,
    lon1: number,
    unit: "miles" | "kilometers" | "meters" = "kilometers"
  ) {
    try {
      if (!this.userLocation)
        this.userLocation = await this.getCurrentLocation();
      // this.userLocation = { latitude: 41.1082021, longitude: -75.4554563 };

      let radius: number;

      // Define el radio según la unidad de medida
      if (unit === "miles") {
        radius = 3958.8; // Earth's radius in miles
      } else if (unit === "kilometers") {
        radius = 6371; // Earth's radius in kilometers
      } else if (unit === "meters") {
        radius = 6371000; // Earth's radius in meters
      } else {
        throw new Error("Unidad de medida no válida");
      }

      const toRadians = (degrees: number) => {
        return degrees * (Math.PI / 180);
      };

      const dLat = toRadians(this.userLocation.latitude - lat1);
      const dLon = toRadians(this.userLocation.longitude - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(this.userLocation.longitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = radius * c;

      return parseFloat(distance.toFixed(2));
    } catch (error) {
      console.log(error);
    }
  }

  async getCurrentLocation() {
    console.log("getCurrentLocation", moment().toDate());
    try {
      const location: any = await this.geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 9000,
      });

      if (location.code == 3 || location.code == 2) {
        const lastLocation = await this.storage.get("lastLocation");
        this.userLocation = lastLocation;
        return this.userLocation;
      } else {
        this.userLocation = {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        };
        await this.storage.set("lastLocation", this.userLocation);
        return this.userLocation;
      }
    } catch (error) {
      console.error("Error getting location", error);

      const lastLocation = await this.storage.get("lastLocation");
      if (lastLocation) {
        this.userLocation = lastLocation;
        return this.userLocation;
      }

      return lastLocation;
    }
  }

  findNearestRegion(userLocation) {
    let nearestRegion = null;
    let shortestDistance = Infinity;
    const regions = environment.regionsData;
    for (const region of regions) {
      const distance = this.calculateDistanceRegions(
        userLocation.latitude,
        userLocation.longitude,
        region.coordinates.lat,
        region.coordinates.lng
      );
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestRegion = region.name;
      }
    }

    return { name: nearestRegion, distance: shortestDistance };
  }
  // Fórmula de Haversine para calcular distancia
  calculateDistanceRegions(
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ): number {
    const R = 6371; // Radio de la Tierra en km
    const toRadians = (deg: number) => deg * (Math.PI / 180);
    const dLat = toRadians(lat2 - lat1);
    const dLng = toRadians(lng2 - lng1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distancia en km
  }

  findMatchingRegion(input: string) {
    const cleanInput = input.trim().toLowerCase();

    // Buscar coincidencia exacta o parcial
    const match = environment.regionsData.find(region => cleanInput.includes(region.name.toLowerCase()) || region.name.toLowerCase().includes(cleanInput));

    return match || environment.regionsData.find(region => region.name === environment.regions.defaultRegion);
  }
}
