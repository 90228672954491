import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { SecureUserRepositoryService } from '@app/core/users-api/secure-user-repository.service';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-to-scavenger-hunts',
  templateUrl: './welcome-to-scavenger-hunts.component.html',
  styleUrls: ['./welcome-to-scavenger-hunts.component.scss'],
})
export class WelcomeToScavengerHuntsComponent implements OnInit {
  public environment = environment;
  videoLoaded: boolean[] = [];
  constructor(
    private userApi: UsersApiService,
    private router: Router,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.videoLoaded = Array(1).fill(false); }

  public async onOver21Tap(ev: any) {
    await this.setOver21(true);
    return await this.modalController.dismiss({
      isOver21: true
    });
  }

  public async onNotOver21Tap(ev: any) {
    await this.setOver21(false);
    return await this.modalController.dismiss({
      isOver21: false
    });
  }

  onVideoCanPlay(index) {
    this.videoLoaded[index] = true;
  }

  start(){
    this.router.navigate(["/tabs/scavenger-hunts/details"])
  }
  private async setOver21(value: boolean) {
    try {
      const af = new Map([
        ['isOver21', `${value}`]
      ]);
      await this.userApi.editUser({ additionalFields: af });
      await this.userApi.getUser();
      return true;
    } catch (error) {
      return false;
    }
  }

}
