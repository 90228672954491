import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent implements OnInit {

  constructor(
    private modal: ModalController,) { }

  ngOnInit() {
    setTimeout(() => this.onButtonClick(), 3000);
  }

  async onButtonClick() {
    return this.modal.dismiss({
      value: 'success'
    });
  }

}
