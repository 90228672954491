import { AgmMap, LatLngBounds } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { Listing } from '@app/core/data/Listing';
import { environment } from '@env/environment';
import { Events, IonSlides } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationEntity } from '../../list-details/list-details.page';
import { timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ThingsToDoComponent } from '@app/codebase/things-to-do/things-to-do.component';
import Long from 'long';
declare function require(name:string);

@Component({
  selector: 'app-trails-map',
  templateUrl: require('template/' + environment.template + '/html/trails-map.component.html'),
  styles: [require('template/' + environment.template + '/styles/trails-map.component.scss')]
})
export class TrailsMapComponent implements OnInit, AfterViewInit  {
  @ViewChild(AgmMap) agmMap;
  @ViewChild('slideWithNav3') slideWithNav3: IonSlides;
  @Input() listings: Listing[];
  @Input() fitBound: boolean = false;
  @Input() zoom: number = 13;
  @Input() minZoom: number = 13;
  @Input() maxZoom: number = 16;
  @Input() customIcon: string = '';

  latitude: number;
  longitude: number;
  userLocation!: LocationEntity;
  selectedItem!: Listing;
  slideImages: string [];
  loadCarousel = true;
  previousIndex: number;
  windowH: number;
  mapH: number;
  @Input() isLoading: Boolean;

  public mapStyles = [
    {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
      ]
    }
  ];




  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 20
  };

  constructor(
    private router: Router,
    private geolocation: Geolocation,
    public events: Events,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.latitude = environment.cms.main_coordinates.latitude;
    this.longitude = environment.cms.main_coordinates.longitude;
  }

  init(){
    this.ngZone.run(() => {
      // Actualiza la interfaz de usuario y activa la detección de cambios si es necesario
      console.log('Updating UI and triggering change detection');
      this.events.subscribe('testevent',async  (data) => {
        console.log("events data ??????", data)
        // if (data.listings) {
        //   this.listings = data.listings.slice(0,100);
        // }
        // var filteredArray = this.listings.filter(function (itm) {
        //   return itm.Latitude;
        // });
        // this.listings = filteredArray;
        // this.trackLocation();

        // this.slideImages = this.listings.map(x=> {
        //   if (!x.Image_List) {
        //     return "themes/{{environment.theme.name}}/assets/placeholder/placeholder.png";
        //   }
        //     var pene =  x.Image_List.split("|")[0];
        //     return pene;
        // });
        console.log("trails-map-testevent")
        var filteredArray = this.listings.filter(function (itm) {
          return itm.Latitude;
        });
        this.listings = filteredArray;
        //this.trackLocation();
        this.listings.sort(function (a, b) {
          return a.Distance - b.Distance;
        });
        this.slideImages = this.listings.map(x=> {
          if (!x.Image_List) {
            return "themes/{{environment.theme.name}}/assets/placeholder/placeholder.png";
          }
            var slideImage =  x.Image_List.split("|")[0];
            return slideImage;
        });
         await this.slideWithNav3.update();
        this.slideWithNav3.slideTo(0);
      });
      console.log(this.slideWithNav3)
    });
  }
  ngAfterViewInit() {
    this.init();
    // Aquí puedes acceder de manera segura a slideWithNav3 después de que la vista se haya cargado completamente
    if (this.slideWithNav3) {
      console.log("cargado")
      // Hacer algo con slideWithNav3
    }else{
      console.log("no cargado")

    }
  }
  async ngOnInit() {
    console.log("on init")
    var filteredArray = this.listings.filter(function (itm) {
      return itm.Latitude;
    });
    this.listings = filteredArray;
    //this.trackLocation();
    this.listings.sort(function (a, b) {
      return a.Distance - b.Distance;
    });
    this.slideImages = this.listings.map(x=> {
      if (!x.Image_List) {
        return "themes/{{environment.theme.name}}/assets/placeholder/placeholder.png";
      }
        var slideImage =  x.Image_List.split("|")[0];
        return slideImage;
    });
    if (this.slideWithNav3) {
      await this.slideWithNav3.update();
      await this.slideWithNav3.slideTo(0);
    }

    this.latitude = this.listings[0].Latitude;
    this.longitude = this.listings[0].Longitude;
    this.listings[0].zIndex = 1000;
    // if (!this.fitBound) {
    //   this.windowH = window.innerHeight-330;
    //   this.mapH = window.innerHeight-50;
    //   this.listings[0].IconWidth = 45;
    //   this.listings[0].IconHeight = 60;
    //   }
    // else {
    //   this.windowH = window.innerHeight-580;
    //   this.mapH = window.innerHeight-440;
    //     if (this.customIcon.length > 0)
    //     this.listings[0].Icon = this.customIcon;
    // }
    this.windowH = window.innerHeight-390;
    this.mapH = window.innerHeight-240;
    if (this.customIcon.length > 0)
      this.listings[0].Icon = this.customIcon;
    this.listings[0].IconWidth = 45;
    this.listings[0].IconHeight = 60;
    this.previousIndex = 0;

  }
  metersToMi(meters: number): number {
    return meters * 0.000621371;
  }

  setDistance() {
    this.mapsAPILoader.load().then(() => {

      var location = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
      this.listings.forEach(item => {
        if (item.Latitude && item.Longitude) {
          const listingLocation = new google.maps.LatLng(item.Latitude, item.Longitude);
          if (google.maps.geometry) {
            const distanceInM = google.maps.geometry.spherical.computeDistanceBetween(location, listingLocation);
            item.Distance = parseFloat(this.metersToMi((Math.round(distanceInM * 100) / 100)).toFixed(2));
          }
          else {
            const distanceInM = this.distance(location.lat, location.lng, listingLocation.lat, listingLocation.lng);
            item.Distance = parseFloat(this.metersToMi((Math.round(distanceInM * 100) / 100)).toFixed(2));
          }
        }
      });
      this.listings.sort(function (a, b) {
        return a.Distance - b.Distance;
      });

    }
  );

  }
  listingClick(listing: any){
    this.router.navigate(['/tabs/listings', listing.ListingID],
      { queryParams: {
        subcategoryTitle : listing.SubCategoryName,
        hasCheckedIn:listing.Task.IsCompleted,
        type:listing.Task.Definition.Type,
        taskId:listing.Task.Id,
        latitude:listing.Task.Definition.Geofence.Latitude,
        longitude:listing.Task.Definition.Geofence.Longitude,
        radius:listing.Task.Definition.Geofence.Radius,
        points: listing.Task.Definition.Value
      } });
  }

  deg2rad(deg) {
    return (deg * Math.PI / 180.0);
  }
  rad2deg(rad) {
    return (rad * 180.0 / Math.PI);
  }
  distance(lat1, lon1, lat2, lon2) {
    var theta = lon1 - lon2;
    var  dist = Math.sin(this.deg2rad(lat1))
                    * Math.sin(this.deg2rad(lat2))
                    + Math.cos(this.deg2rad(lat1))
                    * Math.cos(this.deg2rad(lat2))
                    * Math.cos(this.deg2rad(theta));
    dist = Math.acos(dist);
    dist = this.rad2deg(dist);
    dist = dist * 60 * 1.1515;
    return (dist);
  }
  selectMarker(event, item: Listing, i) {
    if (event.cancelable)
      event.preventDefault();
    //event.preventDefault();
    console.log('selectMarker: ' + i.toString());
    this.slideWithNav3.slideTo(i);
    //this.zoom = 13; // can set a default zoom if needed
  }

  onOpenListingClick(item: Listing) {
    this.router.navigate(['/listings', item.ListingID]);
  }
  onZoomChange(newZoomValue) {
    this.zoom = newZoomValue;
}
  //Method called when slide is changed by drag or navigation
  SlideDidChange(event, slideView) {
      if (this.previousIndex == 0) {
        this.listings[this.previousIndex].IconWidth = 30;
        this.listings[this.previousIndex].IconHeight = 40;
      }
      else {
        this.listings[this.previousIndex].IconWidth = 22;
        this.listings[this.previousIndex].IconHeight = 30;
      }
    this.listings[this.previousIndex].zIndex = 1;

    this.slideWithNav3.getActiveIndex().then(index => {
      this.latitude = this.listings[index].Latitude;
      this.longitude = this.listings[index].Longitude;

      //this.listings[index].Icon = "./themes/{{environment.theme.name}}/assets/map/big-pin.png";
      if (index == 0) {
        this.listings[index].IconWidth = 45;
        this.listings[index].IconHeight = 60;
      }
      else {
        this.listings[index].IconWidth = 45;
        this.listings[index].IconHeight = 60;
      }
      this.listings[index].zIndex = 1000;
      this.previousIndex = index

   });
   if (event.cancelable)
    event.preventDefault();

  }
}
