import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CMSPage } from '@app/core/data/CMSPage';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-application-widget',
  templateUrl: './application-widget.component.html',
  styleUrls: ['./application-widget.component.scss'],
})
export class ApplicationWidgetComponent implements OnInit {
  @Input() parentPageId: number;
  public childrens: CMSPage[] = [];
  constructor(
    private route: ActivatedRoute,
    private dataController: CMSDataControllerService,) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.dataController.getPages({domainId: environment.cms.domainId, parentPageId: this.parentPageId}).subscribe(pages =>{
        this.childrens = pages.filter(obj => obj.active);
      })
    })
  }

}
