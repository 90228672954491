export const settings = {
  languages: [
    { label: "ENGLISH", value: "en" },
  ],
  categoriesMapping: {
    1691: {name: "dine", displayName: "Eat & Drink"},
    1703: {name: "explore", displayName: "Things To Do"},
    1685: {name: "stay", displayName: "Where To Stay"}
  },

  displaySocial: true,
  site: {
    name: 'Laguna-Beach',
    address: '381 Forest Avenue, Laguna Beach, CA 92651',
    phone: '9494979229',
    map: 'https://maps.app.goo.gl/ZmDqg164SMT8XP2y5',
    latitude: 33.5435656,
    longitude: -117.7820089,
    website:'https://www.visitlagunabeach.com/',
    privacyLink:'https://www.visitlagunabeach.com/privacy-policy/',
    twitter:'https://twitter.com/visitlaguna',
    facebook: 'https://www.facebook.com/visitlaguna',
    pinterest:'',
    instagram:'https://www.instagram.com/visitlaguna/',
    youtube:'https://www.youtube.com/user/VisitLagunaBeach',
    splashImage: '../themes/Laguna-Beach/assets/splashscreens/bg_img.png',
    homeEvents: false,
    featuredEvents: true
  },
  organizations: [
    {
      logo: '',
      website: 'https://www.visitlagunabeach.com/',
      phone: '9494979229',
      location: 'https://maps.app.goo.gl/ZmDqg164SMT8XP2y5',
      latitude: 33.5435656,
      longitude: -117.7820089,
      address: '381 Forest Avenue, Laguna Beach, CA 92651',
    }
  ],
  eventsCategorysAllowed: [
    4, 30, 16, 7, 8, 9, 50
  ],
  hasEventsData: true,
  hasEventsDates: true,
  filterEventsByCategories: false,
  filterEventsByfeature: false,
  filterListingsByfeature: false,
  hasRegions: false,
  defaultRegion: null,
  regionsData: [],
  homeOptions: {
    enabled: false,
    optionPageId: 9276,
    fullWithFirstItemMenu: false
  },
  showGradient: true,
  debug: true
};
