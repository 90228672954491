import { Component, OnInit } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { environment } from '@env/environment';
import moment from "moment-timezone";
import { SubCategory } from '@app/core/data/SubCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

import { Storage } from '@ionic/storage';
import { LoadingService } from '../services/loading.service';
import { DeepLinkHelper } from '@app/core/data/utils/DeepLinkHelper';
@Component({
  selector: 'app-featured-events-row',
  templateUrl: require('template/' + environment.template + '/html/featured-events-row.component.html'),
  styles: [require('template/' + environment.template + '/styles/featured-events-row.component.scss')]
})
export class FeaturedEventsRowComponent implements OnInit {

  public environment = environment;
  events!: CMSEvent[];
  listings!: SubCategory[];
  filteredListings!: Map<string,boolean>;
  visibleListings!: SubCategory[];
  categoriesAllowed = environment.eventsCategorysAllowed;
  filterEventsByCategories = environment.filterEventsByCategories;
  visibleEvents!: CMSEvent[];
  userLocation: any;

  language = this.translate.currentLang;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public storage: Storage,
    private loading: LoadingService,
    public translate: TranslateService,
    private dataController: CMSDataControllerService,
    ) {
      storage.get('language').then(lang => {
      translate.setDefaultLang(lang || 'en')
    })
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      const activeRegion = await this.storage.get("activeRegion")
      if (!this.environment.site.featuredEvents) return;
      await this.loading.presentLoading();
      let map = new Map();
      this.events = await this.storage.get("visibleEvents");
      if (this.events){
        this.events = this.events.slice(0,10);
        this.loading.dismiss();
      }else{
        this.userLocation = await this.storage.get("lastLocation");
        if (!this.userLocation) this.userLocation = environment.cms.main_coordinates;
        let promises = [];
        if (environment.hasEventsDates){
          promises.push(
            this.dataController
              .getEventsDates2({
                domainId: environment.cms.domainId,
                dataSyndication: true,
                latitude: this.userLocation.latitude,
                longitude: this.userLocation.longitude,
                daysCount: 28,
                firstDayOfWeek: 0
              })
              .toPromise()
          );
          promises.push(
            this.dataController
              .getEvents2({
                domainId: environment.cms.domainId,
                regionId: activeRegion ? activeRegion.rssLink : null,
                dataSyndication: true,
              })
              .toPromise()
          );
        }else{
            promises.push(
              this.dataController
                .getEvents2({
                  domainId: environment.cms.domainId,
                  regionId: activeRegion ? activeRegion.rssLink : null,
                  dataSyndication: true,
                })
                .toPromise()
            );
        }
        Promise.all(promises).then(
          async (list: any) => {
            if (!list[0]) {
              this.loading.dismiss();
              return;
            }

            // Extraer parámetros de la URL
            const { subcategoryid, featured } = new DeepLinkHelper().parseParameters(params.rssLink || "");
            const isFeatured = featured ? featured.toLowerCase() === "true" : null;

            // Normalizar subcategoryid a un array para evitar errores
            const subcategoryIds = Array.isArray(subcategoryid)
              ? subcategoryid.map(id => +id) // Convertir a números si es un array
              : subcategoryid
              ? [+subcategoryid] // Si es un string, envolverlo en un array y convertir a número
              : []; // Si es null o undefined, usar un array vacío

            // Procesar eventos según configuración del entorno
            if (!environment.hasEventsDates) {
              this.events = list[0].sort((a, b) => a.StartDate - b.StartDate);
            } else {
              let eventDates = this.filterAndSortEventsByCurrentDate(list[0].Result);
              this.events = eventDates;
            }
            // Filtrar por `featured` si está habilitado
            if (environment.filterEventsByfeature) {
              this.events = this.events.filter(obj => isFeatured !== null ? obj.Featured === isFeatured : true);
            }

            // Filtrar eventos por `subcategoryid` si está presente
            if (subcategoryIds.length > 0) {
              this.events = this.events.filter(event => subcategoryIds.includes(+event.SubCategoryID || event.SubCategoryId));
            }

            // Inicializar otras variables
            if (this.filterEventsByCategories && subcategoryIds.length == 0) {
              this.visibleEvents = this.events.filter(event =>
                this.categoriesAllowed.includes(+event.SubCategoryID || event.SubCategoryId)
              ).sort((a,b) => new Date(a.ListingDate || a.StartDate).getTime() - new Date(b.ListingDate || a.StartDate).getTime());
            } else {
              this.visibleEvents = this.events.sort((a,b) => new Date(a.ListingDate || a.StartDate).getTime() - new Date(b.ListingDate || a.StartDate).getTime());
            }

            // Configurar elementos iniciales visibles
            this.loading.dismiss();
            this.events = this.visibleEvents.slice(0, 10);
            // await this.storage.set("visibleEvents", this.visibleEvents);
            // this.count = 10;
            // this.isLoading = false;
          },
          (err) => {
            console.error(err);
            this.loading.dismiss();
          }
        );
        }
      })
  }

  filterAndSortEventsByCurrentDate(events: CMSEvent[]): CMSEvent[] {
    const today = moment().startOf("day");

    // Filter events with dates equal to or after the current day
    const filteredEvents = events.filter((event) => {
      const eventDate = moment.tz(event.StartDate, "UTC").startOf("day");
      return eventDate.isSameOrAfter(today, "day");
    });

    return filteredEvents;
  }

  getValidEventsWithNextOccurrence(
    firstResponse: any[],
    secondData: CMSEvent[]
  ): CMSEvent[] {
    const validEvents: CMSEvent[] = [];

    // Iterate through the response of the first function
    for (const firstEvent of firstResponse) {
      // Find the corresponding event in the second data
      const matchingEvent = secondData.find(
        (event) => event.EventID === firstEvent.EventId || event.EventId === firstEvent.EventId
      );


      // Validate if a corresponding event is found
      if (matchingEvent) {
        // Check if the event has an EndDate
        if (matchingEvent.EndDate) {
          // Validate if the EventDate is within the range StartDate - EndDate
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              matchingEvent.EndDate,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: firstEvent.StartDate,
            });
          }
        } else {
          // The event does not have an EndDate, find the next occurrence from the current day
          const nextOccurrence = moment(matchingEvent.StartDate)
            .startOf("day")
            .add(1, "week"); // You can adjust the logic as needed

          // Validate if the StartDate is within the range StartDate - Next occurrence
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              nextOccurrence,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: nextOccurrence.toISOString(),
            });
          }
        }
      }
    }

    return validEvents;
  }

  format(date: string, format: string): string {
    return moment(date).format(format);
  }

  onClick(event){
    // this.router.navigate(['/tabs/category'], { queryParams: { t: event.SubCategoryName, subCategoryId: event.SubCategoryID } });
    if (event.Id)
      this.router.navigate([`tabs/events/${event.Id}`])
    else if (event.EventId)
      this.router.navigate([`tabs/events/${event.EventId}`])
  }

  inRange(rangeStart: Date, rangeEnd?: Date, eventStartDate?: string, eventEndDate?: string): boolean {
    if (!eventStartDate || !eventEndDate) {
      // an event it's considered candidate to check his range if has a non-null start & end dates
      return false;
    }
    if(new Date(eventStartDate).getTime() >= rangeStart.getTime()){
      return true
    }

    return false;
  }

}
