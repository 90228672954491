import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalAwardsComponent } from '@app/codebase/scavenger-hunts/modal-awards/modal-awards.component';
import { TrailsService } from '@app/shared/services/trails.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: "app-trail-completed",
  templateUrl: "./trail-completed.component.html",
  styleUrls: ["./trail-completed.component.scss"],
})
export class TrailCompletedComponent implements OnInit {
  @Input() badge?: string;
  @Input() trailId?: any;
  @Input() task?: any;
  trail: any;
  type: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private trailService: TrailsService,
    public modalCtrlr: ModalController,
    public modalController: ModalController
  ) {}

  async ngOnInit() {
    this.type = this.route.snapshot.queryParams.type;
    await this.trailService.getInteractiveTrails().then((val) => {
      this.trail = val.find((res) => res.Id == this.trailId);
      //this.mapIcon = this.trail.Image;
    });
  }

  socialLinks(social) {
    if ("twitter" === social) {
      window.open("https://twitter.com/VisitParkCity", "_blank");
    } else if ("facebook" === social) {
      window.open("https://www.facebook.com/VisitParkCity/", "_blank");
    } else if ("youtube" === social) {
      window.open("https://www.pinterest.com.mx/visitparkcityut/", "_blank");
    } else if ("instagram" === social) {
      window.open("https://www.instagram.com/visitparkcity/", "_blank");
    }
  }

  public async dismiss() {
    this.router.navigate(["/tabs/trails"]);
    return await this.modalController.dismiss();
  }

  async onShareTap() {
    this.modalCtrlr.dismiss();
    let myNavigator: any;
    myNavigator = window.navigator;
    if (myNavigator && myNavigator.share) {
      await myNavigator.share({
        title: "Super excited!",
        text: `I just got the ${this.trail.Title} on the Go Hawaii app! #gohawaii`,
        url: "https://staging-hawaii.web.app/login",
      });
    } else {
      window.alert("Can not share at this moment");
    }
  }

  goBack() {
    this.router.navigate(["/tabs/scavenger-hunts/welcome"]);
    this.modalController.dismiss();
  }
  removeHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  async openAwards() {
    this.modalCtrlr.dismiss();
    const modal = await this.modalCtrlr.create({
      component: ModalAwardsComponent,
      cssClass: "my-custom-class-awards",
    });
    await modal.present();
    // return await modal.present();
    modal.onDidDismiss().then(async (detail: any) => {
    });
  }

  async myDismiss(next: boolean) {
    this.router.navigate(["/tabs/trails"]);
    return await this.modalController.dismiss();
  }
}
