import { Banner } from '../Banner';
import { CMSEvent } from '../CMSEvent';
import { CMSPage } from '../CMSPage';
import { Advertisement } from '../Advertisement';
import { DataFeedEndPointParam } from '../DataFeedEndPointParam';
import { Deal } from '../Deal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Listing } from '../Listing';
import { from, Observable } from 'rxjs';
import { SubCategory } from '../SubCategory';
import { BandWango } from '../BandWango';
import { NetworkService, ConnectionStatus } from '../../../services/network.service';
import { OfflineManagerService } from '../../../services/offline-manager.service';
import { Storage } from '@ionic/storage';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { CodebaseTranslateService } from '@app/services/codebase-translate.service';
import { switchMap } from 'rxjs/operators';
import moment from 'moment';
import { EventFilter } from '../EventFilter';

const service = environment.pwaAPI.base_url;
const newservice = environment.pwaAPI.new_base_url;
const _domainId = environment.cms.domainId;
const _cms = environment.cms;

@Injectable({
  providedIn: 'root'
})
export class CMSDataControllerService {
  // language:any = this.translate.language;
  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private storage: Storage,
    // public translate: TranslateService,
    private translate: CodebaseTranslateService,
    private offlineManager: OfflineManagerService
    ) {
      // storage.get('language').then(lang => {
      //   //console.log(lang)
      //   this.translate.setDefaultLang(lang || 'en')
      // })
      // this.language = codebaseTranslateService.language;
  }


  getPages(params: DataFeedEndPointParam, lang?: string): Observable<CMSPage[]> {
    var url: string = service + '?fn=pageNoData&DomainId=' + _domainId;
    if (_cms.accountId) {
      url = `${service}?accountid=${environment.cms.accountId}&channelid=${environment.cms.channelId}&deviceid=${environment.cms.deviceId}&fn=pageNoData`
    }
    if (params.parentPageId) {
      url = url + '&parentPageID=' + params.parentPageId;
    }

    if (lang && lang != 'en' && lang !== undefined) {
      url = url + `&language=${this.translate.language ? this.translate.language.substring(0,2) : "eng"}`;
    }
    return this.http.get<CMSPage[]>(url);
  }

  getEventsTable(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    functionName = 'table';
    var url = `${service}?fn=${functionName}&DomainId=${params.domainId}&name=App_uvwMOBI_Events_MaSp_2`;
    // let lang = this.translate.language;

    // if (lang == "ger"){
    //   lang = "de"
    // }else if (lang == "chi"){
    //   lang = "sc"
    // }
    // if (params.categoryId) {
    //   url = url + `&CID=${params.categoryId}`;
    // }
    // url = url + `&language=${lang ? lang.substring(0,2) : "en"}`;
    return this.http.get<CMSEvent[]>(url);
  }

  getPagesBadwangos(params: DataFeedEndPointParam): Observable<CMSPage[]> {
    var url: string = service + '?fn=pageNoData&DomainId=2366'// + params.domainId;
    if (params.parentPageId)
      url = url + '&parentPageID=' + params.parentPageId;
    return this.http.get<CMSPage[]>(url);
  }

  getBrochures(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    functionName = 'brochures';
    const url = `${service}?fn=${functionName}&accountId=${_cms.accountId}`;
    return this.http.get<CMSEvent[]>(url);
  }


  getAdvertisements(params: DataFeedEndPointParam): Observable<Advertisement[]> {
    var url: string = service + '?fn=omniads&DomainId=' + _domainId;

    if (_cms.accountId) {
      url = `${service}?accountid=${environment.cms.accountId}&channelid=${environment.cms.channelId}&deviceid=${environment.cms.deviceId}&fn=omniads`
    }

    return this.http.get<Advertisement[]>(url);
  }


  getBanners(params: DataFeedEndPointParam, lang?: string): Observable<Banner[]> {
    var url: string = service + `?fn=mobiads&DomainId=${_domainId}`;
    if (_cms.accountId) {
      url = `${service}?accountid=${environment.cms.accountId}&channelid=${environment.cms.channelId}&deviceid=${environment.cms.deviceId}&fn=mobiads`
    }

    if (lang && lang != 'en' && lang !== undefined) {
      url = url + `&language=${this.translate.language ? this.translate.language.substring(0,2) : "eng"}`;
    }
    return this.http.get<Banner[]>(url);
  }

  getDeals(params: DataFeedEndPointParam): Observable<Deal[]> {
    let functionName: string;
    if (params.dataSyndication) {
      functionName = 'dsdeals';
    } else {
      functionName = 'deals';
    }
    const url = `${service}?fn=${functionName}&DomainId=${_domainId}`;
    return this.http.get<Deal[]>(url);
  }

  getDeals2(params: DataFeedEndPointParam): Observable<Deal[]> {
    let url = `${newservice}filter/offers/${environment.theme.appCode}`;
    return this.http.get<Deal[]>(url);
  }

  getListingsSubCat(params: DataFeedEndPointParam): Observable<SubCategory[]> {
    let functionName: string;
    if (params.dataSyndication) {
      functionName = 'dssubcategory';
    } else {
      functionName = 'subcat';
    }
    var url = `${service}?fn=${functionName}&DomainId=${_domainId}`;
    url = url + `&language=${this.translate.language ? this.translate.language.substring(0,2) : "eng"}`;
    return this.http.get<SubCategory[]>(url);
  }

  getEvents(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    functionName = 'events';
    var url = `${service}?fn=${functionName}&DomainId=${_domainId}`;

    if (params.startDate) url += `&startDate=${params.startDate}`;

    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }

    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    return this.http.get<CMSEvent[]>(url);
  }

  getEvents2(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let url = `${newservice}events/${environment.theme.appCode}?CurrentDate=${moment().format("MM-DD-YYYY")}`;
    if (params.categoryId) url+= `&CID=${params.categoryId}`
    if (params.subCategoryId) url+= `&SCID=${params.subCategoryId}`
    // if (params.startDate) url += `&startDate=${params.startDate}`;

    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }

    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    return this.http.get<CMSEvent[]>(url);
  }

  getEventsDates(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    functionName = 'table';
    var url = `${service}?fn=${functionName}&DomainId=${params.domainId}&name=uvwMOBI_Events_${environment.theme.appCode}_Dates`;
    return this.http.get<CMSEvent[]>(url);
  }

  getEventsDates2(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    let api = "https://trueomni-pwa-api-qa.azurewebsites.net/events/byweek?EventsView=App_uvwMOBI_Events_VPEN&EventsDateView=uvwMOBI_Events_VPEN_Dates&geoPosition.Latitude=41.0908526&geoPosition.Longitude=-75.5899585&DaysCount=28&FirstDayOfWeek=0"
    let api1 = "https://trueomni-pwa-api-qa.azurewebsites.net/events/byweek?EventsView=App_uvwMOBI_Events_VPEN&EventsDateView=uvwMOBI_Events_VPEN_Dates&geoPosition.Latitude=41.0908526&geoPosition.Longitude=-75.5899585&DaysCount=28&FirstDayOfWeek=0"
    functionName = 'table';
    var url = `${newservice}events/byweek?EventsView=App_uvwMOBI_Events_${environment.theme.appCode}&EventsDateView=uvwMOBI_Events_${environment.theme.appCode}_Dates&geoPosition.Latitude=${params.latitude}&geoPosition.Longitude=${params.longitude}&DaysCount=${params.daysCount}&FirstDayOfWeek=${params.firstDayOfWeek}`;
    return this.http.post<CMSEvent[]>(url, {});
  }

  getListings(params: DataFeedEndPointParam): Observable<Listing[]> {
    return from(this.getRegion()).pipe(
      switchMap((regionSelected) => {
        let functionName: string;

        if (params.dataSyndication) {
          functionName = 'dslistings';
        } else {
          functionName = 'listings';
        }

        let lang = this.translate.language;
        if (lang && lang.value) {
          lang = lang.value;
        }

        let url = `${service}?fn=${functionName}&DomainId=${_domainId}`;

        if (params.categoryId) {
          url += `&CID=${params.categoryId}`;
        }

        if (params.subCategoryId) {
          url += `&SCID=${params.subCategoryId}`;
        }

        if (params.regionId) {
          url += `&RID=${params.regionId}`;
        }

        return this.http.get<Listing[]>(url);
      })
    );
  }

  getListingsNew(params: DataFeedEndPointParam): Observable<Listing[]> {
        let functionName: string;

        if (params.dataSyndication) {
          functionName = 'dslistings';
        } else {
          functionName = 'listings';
        }

        let lang = this.translate.language;
        if (lang && lang.value) {
          lang = lang.value;
        }

        let url = `${newservice}listings/${environment.theme.appCode}?Latitude=${params.latitude}&Longitude=${params.longitude}`;

        if (params.categoryId) {
          url += `&CID=${params.categoryId}`;
        }

        if (params.subCategoryId) {
          url += `&SCID=${params.subCategoryId}`;
        }

        if (params.regionId) {
          url += `&RID=${params.regionId}`;
        }

        return this.http.get<Listing[]>(url);
  }

  getListings2(params: DataFeedEndPointParam): Observable<Listing[]> {
    return from(this.getRegion()).pipe(
      switchMap((regionSelected) => {
        let functionName: string;

        if (params.dataSyndication) {
          functionName = 'dslistings';
        } else {
          functionName = 'listings';
        }

        let lang = this.translate.language;
        if (lang && lang.value) {
          lang = lang.value;
        }

        let url = `${newservice}listings/${environment.theme.appCode}?Latitude=${params.latitude}&Longitude=${params.longitude}`;

        if (params.categoryId) {
          url += `&CID=${params.categoryId}`;
        }

        if (params.subCategoryId) {
          url += `&SCID=${params.subCategoryId}`;
        }

        if (params.regionId) {
          url += `&RID=${params.regionId}`;
        }

        return this.http.get<Listing[]>(url);
      })
    );
  }
  searchListings(params: DataFeedEndPointParam): Observable<Listing[]> {
    let functionName: string;
    if (params.dataSyndication) {
      functionName = 'dslistings';
    } else {
      functionName = 'listings';
    }

    var url = `${service}?fn=${functionName}&DomainId=${_domainId}&searchTerm=${params.searchTerm}`;
    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }
    if (lang && lang != 'en' && lang !== undefined) {
      url = url + `&language=${this.translate.language ? this.translate.language.substring(0,2) : "eng"}`;
    }
    return this.http.get<Listing[]>(url);
  }

  searchListings2(params: DataFeedEndPointParam) {
    var url = `domainid=${_domainId}&fn=listings&RID=`;
    url = url + `&language=${this.translate.language ? this.translate.language.substring(0,2) : "eng"}`;
    return this.getLocalData(url).then(data => {
      if (!data || !data.length) return (this.searchListings(params).toPromise()).then(data => data.splice(0,100));
      let dataFiltered = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.ListingID === thing.ListingID) === i && thing.SortKey.includes(params.searchTerm)
      ).splice(0,100);
      //console.log(dataFiltered)
      return dataFiltered;
    });
  }

  getPageById(params: DataFeedEndPointParam): Observable<CMSPage[]> {
    const functionName = 'pagenodata';
    const url = `${service}?fn=${functionName}&DomainId=${_domainId}&pageid=${params.pageId}`;
    //console.log(url);
    return this.http.get<CMSPage[]>(url);
  }

  getListingById(params: DataFeedEndPointParam): Observable<Listing[]> {
    let functionName: string;

    if (params.dataSyndication) {
      functionName = 'dslistings';
    } else {
      functionName = 'listings';
    }
    var url = `${service}?fn=${functionName}&DomainId=${_domainId}&listingId=${params.listingId}`;
    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }
    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    //console.log(url)
    return this.http.get<Listing[]>(url);
  }

  getById(params: DataFeedEndPointParam): Observable<Listing[]> {
    let url = `${newservice}listing/${environment.theme.appCode}/${params.listingId}?Latitude=${params.latitude}&Longitude=${params.longitude}`;
    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }
    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    //console.log(url)
    return this.http.get<Listing[]>(url);
  }

  getCategoriesFilter(params: DataFeedEndPointParam){
    let url = `${newservice}Categories/${params.fn}/${params.code}`;
    return this.http.get<EventFilter[]>(url);
  }

  getItineryUrl(params: DataFeedEndPointParam){
    let url = `${newservice}accountURL?accountId=${params.accountId}`;
    return this.http.get<any>(url);
  }

  getEventById(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let functionName: string;
    functionName = 'events';


    var url = `${service}?fn=${functionName}&DomainId=${_domainId}&EventID=${params.eventId}`;
    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }
    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    return this.http.get<CMSEvent[]>(url);
  }

  getEventById2(params: DataFeedEndPointParam): Observable<CMSEvent[]> {
    let url = `${newservice}event/${environment.theme.appCode}/${params.eventId}`;
    let lang = this.translate.language;
    if (lang && lang.value) {
      lang = lang.value;
    }
    // if (lang && lang != 'en' && lang !== undefined) {
    //   url = url + `&language=${lang}`;
    // }
    return this.http.get<CMSEvent[]>(url);
  }

  getDealById(params: DataFeedEndPointParam): Observable<Deal[]> {
    let functionName: string;
    if (params.dataSyndication) {
      functionName = 'deals';
    } else {
      functionName = 'deals';
    }

    var url = `${service}?fn=${functionName}&DomainId=${_domainId}&DealId=${params.dealId}`;
    return this.http.get<Deal[]>(url);
  }

  getDealById2(params: DataFeedEndPointParam): Observable<Deal[]> {
    let url = `${newservice}filter/offers/${environment.theme.appCode}`;

    return this.http.get<Deal[]>(url);
  }

  getBandwangos(): Observable<string> {
    const url = `${service}?domainid=2347&fn=bandwango`;
    return this.http.get<string>(url);
  }

  // Save result of API requests
  private setLocalData(API_STORAGE_KEY, key, data) {
    //console.log(data);
    this.storage.set(`${API_STORAGE_KEY}-${key}`, JSON.stringify(data));
  }

  async getRegion(){
    return await this.storage.get("regionId")
  }

  async getLanguage() {
    return this.translate.language
    // return this.codebaseTranslateService.getLanguage()
  }
  // Get cached API result
  private getLocalData(key): Promise<Listing[]> {
    return this.storage.get(`${key}`);
  }

}
