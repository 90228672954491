import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { TrailsService } from "@app/shared/services/trails.service";
import { ModalController } from "@ionic/angular";
import { UntappdComponent } from "../untappd/untappd.component";
import { CMSDataControllerService } from "@app/core/data/controller/cmsdata-controller.service";
import { environment } from "@env/environment.prod";
import { Listing } from "@app/core/data/Listing";
import { Trail } from "../models/trail";
import { Router } from "@angular/router";
import { LocationEntity } from "../trails-details/trails-details.page";
import { MapsAPILoader } from "@agm/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Storage } from "@ionic/storage";
import { DistanceUtilsService } from "@app/shared/services/distance-utils.service";
declare function require(name:string);

@Component({
  selector: "app-beer-o-meter",
  templateUrl: require('template/' + environment.template + '/html/beer-o-meter.component.html'),
  styles: [require('template/' + environment.template + '/styles/beer-o-meter.component.scss')]
})
export class BeerOMeterComponent implements OnInit, OnDestroy {
  public beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-0.svg";
  public encouragementMessage = "Keep it going";
  public percentage = 0;
  private subscription: Subscription;
  distanceCalculated: boolean;
  isLocationAvailable: boolean;
  userLocation!: LocationEntity;
  @Input() trailId: any;
  trail?: Trail;
  tasks: any[];
  tasksRemaining: any[];
  tasksCompleted: any[];
  mapListings: Listing[];

  constructor(
    private trailService: TrailsService,
    private router: Router,
    private dataController: CMSDataControllerService,
    private mapsAPILoader: MapsAPILoader,
    private storage: Storage,
    private geolocation: Geolocation,
    private distanceUtils: DistanceUtilsService,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    await this.trailService.getInteractiveTrails().then((val) => {
      //console.log(val.find(res => res.Id == this.trailId))
      this.trail = val.find((res) => res.Id == this.trailId);
      console.log(this.trail);
      //this.mapIcon = this.trail.Image;
    });
    this.trailService.loadTrailAndTasksByTrailId(this.trailId).then(async (res) => {
      res.forEach(async (item) => {
        if (!item.Definition.Image || item.Definition.Image == "null") {
          item.Definition.Image = "themes/{{environment.theme.name}}/assets/placeholder/placeholder.png";
        }
      });
      this.tasks = res;
      this.tasksCompleted = this.tasks
        .filter((val) => val.IsCompleted);
        //.slice(0, 1);
      this.tasksRemaining = this.tasks
        .filter((val) => !val.IsCompleted);
        //.slice(0, 1);
      console.log(this.tasksRemaining);
      console.log(this.tasksCompleted);
      this.setDistance()
    });
    this.subscription = this.trailService.trailCompletionPercentage$.subscribe(
      ((value) => {
        this.percentage = value;
        this.beeImage();
      }).bind(this)
    );
  }
  async getCurrentLocation() {
    await this.geolocation
      .getCurrentPosition({ enableHighAccuracy: true, timeout: 2000 })
      .then(async (resp: any) => {
        if (resp.code == 3 || resp.code == 2) {
          const lastLocation = await this.storage.get("lastLocation");
          this.userLocation = lastLocation;
          this.trackLocation();
        } else {
          this.userLocation = {
            latitude: resp.coords.latitude,
            longitude: resp.coords.longitude,
          };
          this.storage.set("lastLocation", this.userLocation);
          this.trackLocation();
        }

      }).catch(async err => {
        console.log(err)
        // if (err.code == 2) this.getCurrentLocation();
        if (err.code == 3 || err.code == 2) {
          const lastLocation = await this.storage.get("lastLocation");
          if (!lastLocation) this.getCurrentLocation();
          else{
            this.userLocation = lastLocation;
            this.trackLocation();
          }
        }

      });
  }

  getRemainingTasks(){
    return this.tasksRemaining;
  }

  getCompletedTasks(){
    return this.tasksCompleted;
  }

  async trackLocation() {
    if (this.tasksRemaining) {
      // this.setDistance();
      this.tasksRemaining = this.tasksRemaining.map((list) => {
        list.Distance =
        list.Distance = this.distanceUtils.calculateDistance(list.Definition.Geofence.Latitude, list.Definition.Geofence.Longitud, 'miles')
        return list;
      });
      this.tasksRemaining = this.ordenarPorDistancia(
        this.tasksRemaining,
        this.userLocation
      );
      this.tasksCompleted = this.tasksCompleted.map((list) => {
        list.Distance = this.distanceUtils.calculateDistance(list.Definition.Geofence.Latitude, list.Definition.Geofence.Longitud, 'miles')
        return list;
      });
      this.tasksCompleted = this.ordenarPorDistancia(
        this.tasksCompleted,
        this.userLocation
      );
    } else {
      console.error("listings not available yet");
    }

  }
  // async trackLocation() {
  //   this.geolocation
  //     .getCurrentPosition()
  //     .then((resp) => {
  //       this.isLocationAvailable = true;
  //       if (this.tasks) {
  //         // compute distance and bind it to each listing
  //         this.userLocation = {
  //           latitude: resp.coords.latitude,
  //           longitude: resp.coords.longitude,
  //         };
  //         this.setDistance();
  //         this.mapListings = this.tasks;
  //       } else {
  //         console.error("listings not available yet");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error getting location", error);
  //     });
  // }
  setDistance() {
    this.tasksRemaining.map(async (item) => {
      if (
        item.Definition.Geofence.Latitude &&
        item.Definition.Geofence.Longitude
      ) {
          item.Distance = await this.distanceUtils.calculateDistance(item.Definition.Geofence.Latitude, item.Definition.Geofence.Longitude, 'miles');
      }
    });
    this.tasksRemaining.sort(function (a, b) {
      return a.Distance - b.Distance;
    });
    this.tasksCompleted.map(async(item) => {
      if (item.Latitude && item.Longitude) {
          item.Distance = await this.distanceUtils.calculateDistance(item.Latitude, item.Longitude, 'miles');
      }
    });
    this.tasksCompleted.sort(function (a, b) {
      return a.Distance - b.Distance;
    });
}

  async calcularDistancia(
    latitud1: number,
    longitud1: number
  ) {
    const value = this.distanceUtils.calculateDistance(latitud1, longitud1, 'miles')

    return value
  }

  gradosARadianes(grados: number): number {
    return (grados * Math.PI) / 180;
  }

  ordenarPorDistancia(
    listings: Listing[],
    userLocation: LocationEntity
  ): Listing[] {
    return listings.sort((list, list2) => {
      // const distancia1 = calcularDistancia(coord1, referencia);
      // const distancia2 = calcularDistancia(coord2, referencia);
      return list.Distance - list2.Distance;
    });
  }

  metersToMi(meters: number): number {
    return meters * 0.000621371;
  }

  deg2rad(deg) {
    return (deg * Math.PI) / 180.0;
  }
  rad2deg(rad) {
    return (rad * 180.0) / Math.PI;
  }

  distance(lat1, lon1, lat2, lon2) {
    var theta = lon1 - lon2;
    var dist =
      Math.sin(this.deg2rad(lat1)) * Math.sin(this.deg2rad(lat2)) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.cos(this.deg2rad(theta));
    dist = Math.acos(dist);
    dist = this.rad2deg(dist);
    dist = dist * 60 * 1.1515;
    return dist;
  }

  goBack() {
    window.history.back();
    this.modalController.dismiss();
  }

  taskClick(task: any) {
    console.log(task);
    this.router.navigate(["/tabs/listings", task.ListingId], {
      queryParams: {
        subcategoryTitle: task.SubCategoryName,
        hasCheckedIn: task.IsCompleted,
        type: task.Definition.Type,
        trailId: task.Definition.TrailId,
        taskId: task.Id,
        latitude: task.Definition.Geofence.Latitude,
        longitude: task.Definition.Geofence.Longitude,
        radius: task.Definition.Geofence.Radius,
        points: task.Definition.Value,
      },
    });
    task = null;
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public beeImage() {
    if (this.percentage === 100) {
      this.beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-100.svg";
      this.encouragementMessage = "All Done!";
    } else if (50 < this.percentage && this.percentage < 100) {
      this.beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-75.svg";
    } else if (30 < this.percentage && this.percentage <= 50) {
      this.beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-50.svg";
    } else if (10 < this.percentage && this.percentage <= 30) {
      this.beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-25.svg";
    } else {
      this.beerIconPath = "../../../themes/{{environment.theme.name}}/assets/beer/beer-0.svg";
    }
    return this.beerIconPath;
  }

  async openUntappd() {
    const modal = await this.modalController.create({
      component: UntappdComponent,
      componentProps: {},
    });
    await modal.present();
  }
}
