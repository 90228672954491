import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { ModalController } from "@ionic/angular";
import { UntappdComponent } from "../untappd/untappd.component";
import { CMSDataControllerService } from "@app/core/data/controller/cmsdata-controller.service";
import { environment } from "@env/environment.prod";
import { Listing } from "@app/core/data/Listing";
import { ScavengerHunt } from "../models/scavenger-hunt";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationEntity } from "../scavenger-hunts-details/scavenger-hunts-details.page";
import { MapsAPILoader } from "@agm/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { ScavengerHuntService } from "@app/shared/services/scavenger-hunt.service";
import { HelpComponent } from "../help/help.component";
import { InitialpopupComponent } from "../initialpopup/initialpopup.component";

@Component({
  selector: "app-beer-o-meter",
  templateUrl: "./beer-o-meter.component.html",
  styleUrls: ["./beer-o-meter.component.scss"],
})
export class BeerOMeterComponent implements OnInit, OnDestroy {
  public beerIconPath = "../../../assets/beer/beer-0.svg";
  public encouragementMessage = "Keep it going";
  public percentage = 0;
  private subscription: Subscription;
  distanceCalculated: boolean;
  isLocationAvailable: boolean;
  userLocation!: LocationEntity;
  @Input() scavengerHuntId: any;
  @Input() title: any;
  scavengerhunt?: ScavengerHunt;
  tasks: any[];
  tasksRemaining: any[];
  tasksCompleted: any[];
  mapListings: Listing[];
  @Input() type: any;

  constructor(
    private scavengerHuntService: ScavengerHuntService,
    private router: Router,
    public route: ActivatedRoute,
    private modalCtrl: ModalController,
    private dataController: CMSDataControllerService,
    private mapsAPILoader: MapsAPILoader,
    private geolocation: Geolocation,
    private modalController: ModalController
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (!this.type) this.type = params.type;
      this.scavengerHuntId = this.route.snapshot.params.joined;
    })
    await this.scavengerHuntService.getInteractiveTrails().then((val) => {
      this.scavengerhunt = val.find((res) => res.Id == this.scavengerHuntId);
    });
    this.subscription = this.scavengerHuntService.trailCompletionPercentage$.subscribe(
      ((value) => {
        this.percentage = value;
        this.beeImage();
      }).bind(this)
    );
  }
  async trackLocation() {
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.isLocationAvailable = true;
        if (this.tasks) {
          // compute distance and bind it to each listing
          this.userLocation = {
            latitude: resp.coords.latitude,
            longitude: resp.coords.longitude,
          };
          this.setDistance();
          this.mapListings = this.tasks;
        }
      })
      .catch((error) => {
      });
  }
  setDistance() {
    if (this.distanceCalculated) {
      //return;
    }
    this.mapsAPILoader.load().then(() => {
      const location = new google.maps.LatLng(
        this.userLocation.latitude,
        this.userLocation.longitude
      );
      this.tasks.forEach((item) => {
        if (item.Latitude && item.Longitude) {
          const listingLocation = new google.maps.LatLng(
            item.Latitude,
            item.Longitude
          );
          if (google.maps.geometry) {
            const distanceInM =
              google.maps.geometry.spherical.computeDistanceBetween(
                location,
                listingLocation
              );
            item.Distance = parseFloat(
              this.metersToMi(Math.round(distanceInM * 100) / 100).toFixed(2)
            );
          } else {
            const distanceInM = this.distance(
              this.userLocation.latitude,
              this.userLocation.longitude,
              item.Latitude,
              item.Longitude
            );
            item.Distance = parseFloat(
              this.metersToMi(Math.round(distanceInM * 100) / 100).toFixed(2)
            );
          }
        }
      });
      this.tasks.sort(function (a, b) {
        return a.Distance - b.Distance;
      });
    });
  }

  async displayHelp() {
    // this.translateService.languageChange(this.homePage.languageSelected.val);
    const modal = await this.modalController.create({
      cssClass: "scavenger-hunt-popup",
      component: InitialpopupComponent,
      componentProps: {

      },
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
  }
  metersToMi(meters: number): number {
    return meters * 0.000621371 * 1.60934;
  }

  deg2rad(deg) {
    return (deg * Math.PI) / 180.0;
  }
  rad2deg(rad) {
    return (rad * 180.0) / Math.PI;
  }

  distance(lat1, lon1, lat2, lon2) {
    var theta = lon1 - lon2;
    var dist =
      Math.sin(this.deg2rad(lat1)) * Math.sin(this.deg2rad(lat2)) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.cos(this.deg2rad(theta));
    dist = Math.acos(dist);
    dist = this.rad2deg(dist);
    dist = dist * 60 * 1.1515;
    return dist;
  }

  goBack() {
    window.history.back();
    this.modalController.dismiss();
  }

  socialLinks(social) {
    if ("twitter" === social) {
      window.open("https://twitter.com/VisitParkCity", "_blank");
    } else if ("facebook" === social) {
      window.open("https://www.facebook.com/VisitParkCity/", "_blank");
    } else if ("youtube" === social) {
      window.open("https://www.pinterest.com.mx/visitparkcityut/", "_blank");
    } else if ("instagram" === social) {
      window.open("https://www.instagram.com/visitparkcity/", "_blank");
    }
  }

  taskClick() {
    this.router.navigate([`/tabs/scavenger-hunts/details/${+this.route.snapshot.queryParams.trailId}`]);
    this.goBack();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public beeImage() {
    if (this.percentage === 100) {
      this.beerIconPath = "../../../assets/beer/beer-100.svg";
      this.encouragementMessage = "All Done!";
    } else if (50 < this.percentage && this.percentage < 100) {
      this.beerIconPath = "../../../assets/beer/beer-75.svg";
    } else if (30 < this.percentage && this.percentage <= 50) {
      this.beerIconPath = "../../../assets/beer/beer-50.svg";
    } else if (10 < this.percentage && this.percentage <= 30) {
      this.beerIconPath = "../../../assets/beer/beer-25.svg";
    } else {
      this.beerIconPath = "../../../assets/beer/beer-0.svg";
    }
    return this.beerIconPath;
  }

  async openUntappd() {
    const modal = await this.modalController.create({
      component: UntappdComponent,
      componentProps: {},
    });
    await modal.present();
  }
}
